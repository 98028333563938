import store from "@/store";
import axios from "axios";


// local forage takes bit to be ready
export const waitForStorageToBeReady = async () => {
  console.log('Waiting localForage')
  await store.restored
}

export const setAxiosAuthToken =  (token) => {
  store.state.authenticated = true
  axios.defaults.headers.common['Authorization'] = 'Token ' + token
}
