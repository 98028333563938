<template>
  <div>
    <section class="hero has-background-white">
      <div class="hero-body">
        <div class="columns is-centered pt-2 pb-2">
          <div class="column is-6 has-text-centered pl-5 pr-5">
            <figure class="image">
              <img src="../assets/img/beerpicture.png"/>
            </figure>
          </div>
          <div class="column is-6 has-text-centered pl-5 pr-5 is-flex is-align-items-center">
            <div class="media-content text-content">
              <h2 class="is-3 has-text-black has-text-weight-bold">Tervetuloa Tuuneriin!</h2>
              <p class="mb-6">
                Palvelumme kautta saat tilattua olutta omalla etiketilläsi. Tuunerin käyttö on erittäin helppoa. Voit suunnitella etiketin
                itse tai valita valmiista teemoista.
              </p>
              <!--p class="mb-2">
                <a>
                  Pyydä Tuuneri -tunnukset
                </a>
              </p-->
              <p>
                <a @click="$refs.info.closeModal(); $refs.info.showModal()">
                  Ohjeita etiketin suunnitteluun
                </a>
                <info-modal ref="info"></info-modal>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import infoModal from "@/components/infoModal";

export default {
  name: 'ColumnHome',
  components: {
    infoModal
  },
}
</script>
<style scoped lang="scss">

.text-content {
  font-size: 24px;
  color: Black;
  text-align: left;
}

h2 {
  font-family: 'Passion Bold';
  font-size: 36px;
}

p {
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: bold;

  a {
    color: #00a5ff;
  }
  a:hover {
    color: #89E2F9;
  }
}
</style>
