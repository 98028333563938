<template>
  <div>
    <div class="nav-height">
      <LeftMenuHeader type="summary"/>
    </div>
    <div class="page-setting">
      <div class="container">
        <div class="columns is-paddingless">
          <div class="column is-hidden-touch is-2-desktop is-2-widescreen is-1-fullhd pr-0 pt-0 bg-color">
            <LabelOptionNavbar active="summary"/>
          </div>
          <div class="column is-12-tablet is-10-desktop is-10-widescreen is-11-fullhd pl-0 pt-0">
            <SummaryLeftPage/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryLeftPage from './SummaryLeftPage'
import LabelOptionNavbar from './LabelOptionNavbar'
import LeftMenuHeader from './LeftMenuHeader'

export default {
  name: 'SummaryLeftCom',
  components: {
    SummaryLeftPage,
    LabelOptionNavbar,
    LeftMenuHeader,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>

.page-setting {
  background-color: #282C43;
}

.nav-height {
  height: 80px;
}

.bg-color {
  background-color: #202335;
}
</style>
