<template>
  <div class="bg-color">
    <SpinnerModal :spinning="apiCallRunnin"></SpinnerModal>
    <section class="hero bg-color is-fullheight-with-navbar">
      <div class="hero-body">
        <div class="container p-4 pt-0">
          <OrderSummaryComponent/>
          <div class="p-5 mb-5">
            <!--discount-code class="mb-6"></discount-code-->

            <h3 class="has-text-white text-heading-small pb-2">Tilausnumero</h3>
            <input v-model="contact.customer.order_number" class="input" placeholder="" type="text" maxlength="7"/>
            <p v-if="$v.contact.customer.order_number.$error || order_number_error" class="help is-danger">Tilausnumero on väärin</p>

            <h3 class="text-heading-small has-text-white mt-6">Yhteystiedot</h3>
            <div class="field">
              <div class="control">
                <input v-model=contact.name class="input" type="text" placeholder="Sinun nimesi"
                />
              </div>
              <p v-if="$v.contact.name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
            </div>

            <!--div class="field">
              <div class="control">
                <input v-model=contact.recv_name class="input" type="text" placeholder="Lähetyksen vastaanottajan nimi"
                />
              </div>
              <p v-if="$v.contact.recv_name.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
            </div-->

            <div class="field">
              <div class="control">
                <input v-model=contact.cust_email class="input" type="email" placeholder="Sähköpostiosoite"/>
              </div>
              <p v-if="$v.contact.cust_email.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
            </div>

            <!--div class="field">
              <div class="control">
                <input v-model=contact.recv_company class="input" type="text" placeholder="Yrityksen/Yhdistyksen nimi"/>
              </div>
              <p v-if="$v.contact.recv_company.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
            </div>

            <div class="field">
              <div class="control">
                <input v-model=contact.recv_phone class="input" type="text" placeholder="Vastaanottajan puhelinnumero"/>
              </div>
              <p v-if="$v.contact.recv_phone.$error" class="help is-danger">Anna oikea sähköpostiosoite</p>
            </div>

            <div class="field">
              <div class="control">
                <input v-model=contact.recv_street class="input" type="text" placeholder="Katuosoite"/>
              </div>
              <p v-if="$v.contact.recv_street.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
            </div>

            <div class="field">
              <p class="control">
                <input v-model=contact.recv_zip class="input" type="text" placeholder="Postinumero"/>
              </p>
              <p v-if="$v.contact.recv_zip.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
            </div>

            <div class="field">
              <p class="control">
                <input v-model=contact.recv_town class="input" type="text" placeholder="Postitoimipaikka"/>
              </p>
              <p v-if="$v.contact.recv_town.$error" class="help is-danger">Tämä kenttä on pakollinen</p>
            </div-->


            <!--div class="field pt-4">
              <div class="flex-column">
                <h3 class="text-heading-small has-text-white">Toimitustiedot</h3>
                <label class="container-check-box has-text-white ">
                  <input type="checkbox" v-model="needBillingAddress"/>
                  <span class="text-size-small-min">Toimitusosoite eri kuin yhteystiedot:</span>
                  <span class="checkmark"></span>
                </label>
              </div>
              <div v-if="needBillingAddress">
                <div class="control pt-5">
                  <input v-model="contact.bill_name" class="input" placeholder="Sinun nimesi" type="text"/>
                </div>
                <div class="control flex-set pt-2">
                  <input v-model="contact.bill_company" class="input" placeholder="Yrityksen/Yhdistyksen nimi" type="text"/>
                </div>
                <div class="control flex-set pt-2">
                  <input v-model="contact.bill_phone" class="input" placeholder="Vastaanottajan puhelinnumero" type="text"/>
                </div>
                <div class="control flex-set pt-2">
                  <input v-model="contact.bill_street" class="input" placeholder="Katuosoite" type="text"/>
                </div>
                <div class="control flex-set pt-2">
                  <input v-model="contact.bill_zip" class="input" placeholder="Postinumero" type="text"/>
                </div>
                <div class="control flex-set pt-2">
                  <input v-model="contact.bill_town" class="input" placeholder="Postitoimipaikka" type="text"/>
                </div>
                <div class="control flex-set pt-2">
                  <input v-model="contact.cust_vat_nr" class="input" placeholder="Y tunnus" type="text"/>
                </div>
              </div>
            </div-->
          </div>

          <div>
            <div class="flex-row space-between">
              <button class="button is-rounded is-outlined is-warning has-background-white white-bg"
                      @click="addNewItem()">
                <span class="icon">
                  <img alt="icon" class="icon" src="../assets/ArrowCircleLeft.svg" svg-inline svg-sprite/>
                </span>
                <span>Lisää uusi</span>
              </button>

              <button class="button btncolor is-rounded" @click="submitForm()">
                <span class="has-text-white">Seuraava</span>
                <span class="icon">
                  <img alt="icon" class="icon" src="../assets/ArrowCircleRight.svg" svg-inline svg-sprite/>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex"
import {email, required} from "vuelidate/lib/validators"

import SpinnerModal from './SpinnerModal'
import OrderSummaryComponent from './OrderSummaryComponent'
import {SVG_LABEL_CONFIGS} from "@/store/config";

export default {
  name: 'OrderLeftPage',
  components: {
    SpinnerModal,
    OrderSummaryComponent
  },
  data() {
    return {
      apiCallRunnin: false,
      order_number_error: false,
      contact: {
        name: '',
        recv_name: '',
        recv_company: '',
        recv_phone: '',
        recv_street: '',
        recv_zip: '',
        recv_town: '',

        bill_name: '',
        bill_company: '',
        bill_phone: '',
        bill_street: '',
        bill_zip: '',
        bill_town: '',

        cust_email: '',
        cust_vat_nr: '',
        customer: {
          order_number: ''
        }
      }
    }
  },
  validations() {
    return {
      contact: {
        name: {
          required
        },
        recv_name: {},
        recv_company: {},
        recv_phone: {},
        recv_street: {},
        recv_zip: {},
        recv_town: {},

        cust_email: {
          email,
          required,
        },
        cust_vat_nr: {},

        customer: {
          order_number: {
            required,
          },
        },

        bill_name: {},
        bill_company: {},
        bill_phone: {},
        bill_street: {},
        bill_zip: {},
        bill_town: {}
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$store.state.postContactData)
        this.contact = Object.assign({}, this.contact, this.$store.state.postContactData)
      let self = this
      // remove picture placeholder for pdf creation
      setTimeout(async () => {
        self.removeLogoIfEmpty()
      }, 200)
    })
  },
  methods: {
    ...mapActions({
      updateOrder: 'updateOrder',
      postContact: 'postContact',
      updateLabelsAmount: 'updateLabelsAmount'
    }),
    ...mapMutations({
      changeBillAddressOption: 'changeBillAddressOption',
      revertFakedTaglines: 'revertFakedTaglines',
      addNewItemToCart: 'addNewItem',
    }),
    ...mapGetters({
      getOrderUuid: 'getOrderUuid'
    }),
    async submitForm() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.apiCallRunnin = true
      let self = this
      this.updateLabelsAmount()
      self.contact.orderUuid = self.getOrderUuid()
      self.postContact(self.contact).then(() => {
        this.apiCallRunnin = false
        self.$router.push('/summary')
      }).catch((error) => {
        self.apiCallRunnin = false
        self.order_number_error = true
      })
    },
    goBack() {
      this.revertFakedTaglines()
      this.$router.push({name: 'Label', params: {uuid: this.getOrderUuid()}})
    },
    addNewItem() {
      this.addNewItemToCart()
      this.$router.push({name: 'Label', params: {uuid: this.getOrderUuid()}})
    },
    removeLogoIfEmpty() {
      let svgContainer = document.getElementById("parent-svg-container")
      let svgLogo = svgContainer.querySelector('#svg-logo')
      let svgMainImage = svgContainer.querySelector('#svg-main-image')
      if (this.labelsData[this.selectedCartItem].label_data.logo == "" && svgLogo) {
        svgLogo.outerHTML = ""
      }
      if (this.labelsData[this.selectedCartItem].label_data.mainPicture == "" && svgMainImage) {
        svgMainImage.outerHTML = ""
      }
    }
  },
  computed: {
    ...mapState(['billAddress', 'orderAmount', 'labelsData', 'selectedCartItem']),
    needBillingAddress: {
      get() {
        return this.billAddress
      },
      set(newVal) {
        this.changeBillAddressOption(newVal)
      }
    },
  },
  watch: {
    selectedCartItem() {
      this.removeLogoIfEmpty()
    }
  }
}
</script>

<style lang="scss" scoped>

.bg-color {
  background-color: #282c43;
}

.flex-set {
  display: flex;
  align-items: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  justify-content: center;
}

.border {
  border-top: 2px solid rgb(33, 211, 255);
}

.border-black {
  border-top: 8px solid rgb(0, 0, 0);
}

.border-dot {
  border-top: 3px dotted rgb(0, 0, 0);
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.flexend {
  display: flex;
  justify-content: flex-end;
}

.selects {
  background-color: DodgerBlue;
}

.btncolor {
  background: #e9721f;
}

.checkboxsize {
  height: 25px;
  width: 25px;
  background-color: rgb(238, 20, 20);
}

.amount-input {
  max-width: 60px;
}

.vat-text {
  padding-top: 0 !important;
  font-size: 0.75rem;
  margin-bottom: 20px;
}

.bg-color {
  background-color: #202335;
}
</style>
