<template>
  <div class="home-footer-wrapper">
    <div>
      <nav v-if="$route.name != 'Home'" aria-label="main navigation" class="navbar is-dark" role="navigation">
        <div class="navbar-brand">
          <a class="logocolor imgsetting is-invisible" @click="$router.push('/')">
            <img alt="Placeholder image" src="../assets/Tuunerilogonav.svg"/>
          </a>
        </div>
        <div class="navbar-menu">
          <div class="navbar-end">
            <p class="navbar-item has-text-weight-bold">Tuuneri powered by: Polarmonks Oy</p>
          </div>
        </div>
      </nav>
      <div v-else>
        <div class="container home-footer">
          <div class="columns">
            <div class="column is-2 is-hidden-touch">
              <div class="navbar-brand">
                <a class="logocolor imgsetting is-invisible" @click="$router.push('/')">
                  <img alt="Placeholder image" src="../assets/Tuunerilogonav.svg"/>
                </a>
              </div>
            </div>
            <div class="column is-3">
              <a class="navbar-item pt-3" @click="$refs.terms.closeModal(); $refs.terms.showModal()">
                Yleiset Toimitusehdot
              </a>
              <terms-modal ref="terms"></terms-modal>
            </div>
            <div class="column is-4">
              <a class="navbar-item pt-3" @click="$refs.register.closeModal(); $refs.register.showModal()">
                Asiakasrekisterin Tietosuoja
              </a>
              <register-modal ref="register"></register-modal>
            </div>
          </div>
          <div class="cookies-text">
            Tämä sivusto käyttää evästeitä
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import termsModal from "@/components/termsModal";
import registerModal from "@/components/registerModal";

export default {
  name: 'Footer',
  components: {
    termsModal,
    registerModal
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">

.imgsetting {
  width: 150px;
  height: fit-content;
  padding: 10px 30px 10px 10px;
}

.home-footer {
  background-color: #363636;
  text-align: center;
  .navbar-item {
    color: white;
  }
  .navbar-item:hover {
    background-color: #363636;
    font-weight: bold;
  }
}

.home-footer-wrapper {
  background-color: #eeeeee;
  .navbar-brand {
    background-color: hsl(0deg, 0%, 21%);
  }
  .navbar-menu {
    background-color: hsl(0deg, 0%, 21%);
  }
}

.cookies-text {
  color: white;
  text-align: center;
  background: #d78e08;
  margin: 0 auto;
  padding-top: 10px;
}

</style>
