<template>
  <div class="modal" :class="{'is-active': active}">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="close-button-wrapper" @click="closeModal()">
          <i class="far fa-times-circle"></i>
        </div>
      </header>
      <section class="modal-card-body has-text-centered">
          <ul>
            <li class="mb-5">
              <a @click="$refs.terms.closeModal(); $refs.terms.showModal()">
                Yleiset Toimitusehdot
              </a>
            </li>
            <terms-modal ref="terms"></terms-modal>
            <li class="mb-5">
              <a @click="$refs.register.closeModal(); $refs.register.showModal()">
                Asiakasrekisterin Tietosuoja
              </a>
            </li>
            <register-modal ref="register"></register-modal>
          </ul>
      </section>
    </div>
  </div>
</template>

<script>
import termsModal from "@/components/termsModal";
import registerModal from "@/components/registerModal";

export default {
  name: 'mobileMenuModal',
  data() {
    return {
      active: false
    }
  },
  components: {
    termsModal,
    registerModal
  },
  methods: {
    closeModal(){
      this.active = false;
    },
    showModal(){
      this.active = true;
    }
  }
}
</script>
<style lang="scss" scoped>

.modal-card {
  width: 50%;
}

.content {
  text-align: left;
}

h2 {
  color: black;
}

p {
  color: black;
}

li {
  a {
    color: black;
    font-family: 'Avenir';
  }
}

.close-button-wrapper {
  width: 100%;
  display: inline-block;
  svg {
    float: right;
    color: black;
  }
}
</style>
