<template>
  <div class="height order">
    <div class="hero">
      <div class="hero-body is-paddingless">
        <div class="container is-fullhd">
          <div class="columns is-marginless" v-if="!isMobile">
            <div class="column is-6 is-paddingless">
              <OrderLeftCom/>
            </div>
            <div class="column is-6 pl-0 pr-0 bottle-fixed" ref="svgContainer">
              <RightBottleSection/>
            </div>
          </div>
          <div v-else class="columns is-multiline is-marginless is-hidden-desktop" ref="svgContainer">
            <div class="column is-12 pl-0 pr-0 pt-0">
              <RightBottleSection/>
            </div>
            <div class="column is-12 is-paddingless">
              <OrderLeftCom/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderLeftCom from '../components/OrderLeftCom'
import RightBottleSection from '../components/RightBottleSection'
import ScreenSizeMixin from '@/mixins/ScreenSizeMixin'

export default {
  name: 'Order',
  components: {
    OrderLeftCom,
    RightBottleSection,
  },
  mixins: [ScreenSizeMixin],
  data() {
    return {}
  },
  mounted() {
    // remove ?edit=true from url
    window.history.pushState({}, document.title, window.location.href.split("?")[0])
  }
}
</script>

<style lang="scss">

.nav-height {
  height: 80px;
}

.height {
  height: fit-content;
}

.order .black-bar {
  @media (max-width: 319px) {
    top: 126px;
  }
  @media screen and (max-width: 768px) and (min-width: 320px) {
    top: 101px;
  }
  @media screen and (min-width: 1044px) {
    top: 64px;
  }
  @media screen and (max-width: 1043px) and (min-width: 1024px) {
    top: 79px;
  }
  @media screen and (max-width: 1023px) and (min-width: 769px) {
    top: 103px;
  }
}

.hero.bg-color {
  background-color: #282c43 !important;
}
</style>
