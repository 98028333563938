<template>
  <div class="height summary">
    <div class="hero">
      <div class="hero-body is-paddingless">
        <div class="container is-fullhd">
          <div class="columns is-marginless" v-if="!isMobile">
            <div class="column is-6 is-paddingless">
              <SummaryLeftCom/>
            </div>
            <div class="column is-6 pl-0 pr-0 bottle-fixed" ref="svgContainer">
              <RightBottleSection/>
            </div>
          </div>
          <div class="columns is-multiline is-marginless is-hidden-desktop" ref="svgContainer" v-else>
            <div class="column is-12 pl-0 pr-0">
              <RightBottleSection/>
              </div>
            <div class="column is-12 is-paddingless">
              <SummaryLeftCom/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryLeftCom from '../components/SummaryLeftCom'
import RightBottleSection from '../components/RightBottleSection'
import ScreenSizeMixin from '@/mixins/ScreenSizeMixin'

export default {
  name: 'Summary',
  components: {
    SummaryLeftCom,
    RightBottleSection,
  },
  mixins: [ScreenSizeMixin],
  data() {
    return {}
  },
}
</script>

<style lang="scss">


.nav-height {
  height: 80px;
}

.height {
  height: fit-content;
}

.summary .black-bar {
  top: 9px;
}
</style>
