<template>
  <div class="height">
    <div class="page-setting">
      <CompanyInfo/>
    </div>
  </div>
</template>

<script>
import CompanyInfo from '../components/CompanyInfo'

export default {
  name: 'SignIn',
  components: {
    CompanyInfo,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>

.page-setting {
  display: flex;
  justify-content: center;
  align-items: center;

}


.height {
  //height: fit-content;
  background-color: #282c43;
}
</style>
