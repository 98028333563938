<template>
  <div>
    <section class="bg-color has-background-white">
      <div class="columns ">
          <div class="column is-full pt-0">
            <div class="carousel-wrapper">
              <div class="pm-carousel">
                <div>
                  <img src="../assets/img/slide1@2x.png"/>
                </div>
                <div>
                  <img src="../assets/img/slide2@2x.png"/>
                </div>
                <div>
                  <img src="../assets/img/slide3@2x.png"/>
                </div>
              </div>
              <div class="home-logo">
                <img src="../assets/img/tuunerilogo_black@2x.png"/>
              </div>
              <div class="home-service-button-wrapper">
                <div class="home-service-button">
                  <img @click="$router.push('/signin')" src="../assets/img/bigbutton.png"/>
                </div>
              </div>
            </div>
            <div class="grey-divider-left">
              <img src="../assets/img/grey_divider_1@2x.png"/>
            </div>
            <div class="grey-divider-right">
              <img src="../assets/img/grey_divider_2@2x.png"/>
            </div>
          </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'HeroHome',
  data() {
    return {}
  },
  mounted() {
    $(document).ready(function(){
      $('.pm-carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true
      });
    })
  }
}
</script>
<style scoped lang="scss">

.bg-color {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.home-service-button {
  img:hover {
    cursor: pointer;
  }
}

.carousel-wrapper {
  position: relative;
  .home-logo {
    display: none;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
      width: 300px;
      padding-top: 20%;
    }
  }
  .home-service-button-wrapper {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @media (min-width: 900px) {
      .home-service-button {
        padding-top: 380px;
      }
    }
    @media (max-width: 400px) {
      .home-service-button {
        padding-top: 118px;
        width: 180px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media screen and (max-width: 900px) and (min-width: 750px) {
      .home-service-button {
        padding-top: 320px;
      }
    }

    @media screen and (max-width: 750px) and (min-width: 600px) {
      .home-service-button {
        padding-top: 230px;
      }
    }

    @media screen and (max-width: 600px) and (min-width: 500px) {
      .home-service-button {
        padding-top: 180px;
      }
    }

    @media screen and (max-width: 500px) and (min-width: 400px) {
      .home-service-button {
        padding-top: 165px;
        width: 180px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.grey-divider-left {
  width: 30%;
  position: absolute;
  left: 12%;
}

.grey-divider-right {
  width: 30%;
  position: absolute;
  right: 12%;
}
</style>
