<template>
  <div class="modal" :class="{'is-active': active}">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <div class="has-text-centered py-2 pl-6 ml-4 is-invisible">
          <span>
            <img alt="background" src="../assets/Tuunerilogonav.svg" svg-inline svg-sprite/>
          </span>
        </div>
        <!-- <button class="delete" aria-label="close"></button> -->
      </header>
      <section class="modal-card-body has-text-centered">
          <!-- Content ... -->
          <div class="content pb-6">
            <h2>Tuuneri yrityspalvelun tilaus- ja toimitusehdot 30.3.2021</h2>
            <h2>1. Soveltamisala</h2>

            <p>
              Näitä yleisiä toimitusehtoja sovelletaan Polarmonks Oy:n ylläpitämän
              Tuuneri (jäljempänä ”Tuuneri”)sivuston kautta tehdyissä Tuuneriin Oyj:n ja/tai
              sen tytäryhtiön Servaali Oy:n (jäljempänä yhdessä/erikseen ”Myyjä”) (Tuote)
              tilauksissa sekä (Tuote) toimituksissa, ellei joltain osin kirjallisesti
              toisin sovita.
            </p>

            <h2>2. Rekisteröityminen</h2>


            <p>
              Ostaja/Asiakas ((jäljempänä Asiakas”) rekisteröityy palvelun käyttäjäksi
              yrityksen nimellä sekä Tuuneriin Oyj:n asiakasnumerolla. Asiakas vastaa Tuuneri
              palveluun antamiensa tietojen oikeellisuudesta. Käyttäjätunnusta tai salasanaa
              ei saa luovuttaa edelleen. Polarmonks Oy:llä sekä Tuuneriin Oyj:llä on kuitenkin
              oikeus evätä rekisteröityminen ja oikeus tarvittaessa sulkea Asiakkaan
              käyttäjätunnus.
            </p>

            <h2>3. Tilaus</h2>

            <p>
              Tuotteet tilataan verkkokaupan välityksellä. Tilaus tulee Myyjää sitovaksi, kun
              se on erikseen Myyjän toimesta vahvistettu. Myyjä pidättää itsellään oikeuden
              olla hyväksymättä tilausta esimerkiksi siinä tapauksessa, että personoitu tilaus
              on myyjän mielestä hyvien tapojen vastainen tai muuten sopimaton tai jos Myyjällä
              on syytä epäillä asiakkaan maksukykyä. Myyjä voi käyttää tätä oikeutta myös
              tilausvahvistuksen toimittamisen jälkeen. Myyjä pyrkii ilmoittamaan asiakkaalle
              mahdollisimman pian asiasta. Asiakkaan on huolellisesti tarkastettava suunniteltu
              tuote ennen tilauksen hyväksymistä. Asiakas hyväksyy tilattavan tuotteen sekä
              personoidut etiketit hyväksymällä tilauksen. Asiakkaalla ei ole oikeutta peruuttaa
              tilausta, joka koskee personoituja tuotteita tai tilaustöitä, kun niiden tuotanto
              on aloitettu.
            </p>

            <h2>4. Toimitus</h2>


            <p>
              Arvioitu toimitusaika ilmoitetaan tilausvahvistuksen yhteydessä. Arvio ei sido Polarmonks Oy:tä
              eikä Tuuneriin Oyj:tä. Tuotteen toimituskulut lisätään laskuun aina todellisten kustannusten
              mukaisesti. Palvelun kautta tilatut tuotteet toimitetaan kumppanimme varastolta. Näiden
              tuotteiden käsittely- ja toimitusaika on muita tuotteita pidempi, arviolta 10-20 arkipäivää
              ellei toisin sovita. Tuotteet toimitetaan asiakkaan ilmoittamaan yrityksen osoitteeseen.
              Tuotteita voi tilata yhteen tai useampaan toimitusosoitteeseen. Asiakas vastaa toimitusosoitteen
              oikeellisuudesta. Lähetämme tilaajalle tilatuista tuotteista erikseen omat toimitusvahvistukset
              ja seurantalinkit, kun tuotteet lähtevät kumppanimme varastolta. Asiakas vastaa siitä, että
              toimituksen mukaiset tuotteet myydään tilauksessa mainitun luvanhaltijan toimesta. Myyjä ei
              vastaa toimituksen viivästymisestä tai ennenaikaisuudesta asiakkaalle mahdollisesti aiheutuvista
              välittömistä tai välillisistä vahingoista. Tilatut tuotteet toimitetaan Suomessa Tuuneriin Oyj:n tai
              sen sopimuskumppaneiden logistiikan välityksellä.
            </p>

            <h2>5. Kauppahinta</h2>

            <p>
              Tuotteiden hinnat sekä toimitusmaksu määräytyvät Myyjän kunakin aikana voimassa olevan hinnaston
              perusteella. Myyjän tuotteelle sekä toimitukselle määritellyt ajantasaiset hinnat ovat esillä
              tilausjärjestelmässä. Myyjä pidättää oikeuden hintojen muutoksiin ilman eri ilmoitusta. Kaikki
              hinnat, alennukset ja muut etuudet sisältävät arvonlisäveron. Myyjä pidättää oikeuden vaatia
              turvaavaa vakuutta kaikille ennakkoon maksettaville suorituksille.
            </p>

            <h2>6. Maksu</h2>

            <p>
              Maksuehdot ilmenevät laskuista. Maksuviivästyksen perusteella Myyjällä on oikeus vähentää/kuitata
              Asiakkaalle kertyneet hyvitykset (vuosialennukset, volyymimarkkinointituet yms.) Asiakkaan velkoja
              vastaan. Sama oikeus Myyjällä on myös muun sopimusrikkomuksen perusteella. Asiakas on velvollinen
              maksamaan viivästyneiden maksujen huomautus- ja muistutuskulut sekä viivästymisajalta 16 %:n vuotuista
              viivästyskorkoa ja korvaamaan perintäkulut. Maksuviivästyksen perusteella Myyjällä on edelleen oikeus
              keskeyttää sopimusvelvoitteensa täyttäminen välittömästi ilman korvausvelvollisuutta ja asettaa Asiakas
              toimituskieltoon. Myyjällä on oikeus purkaa sopimus päättymään välittömästi, mikäli maksu viivästyy yli
              kymmenen ( 10 ) päivää siitä, kun Asiakkaalle on annettu muistutus.
            </p>

            <h2>7. Sopimusrikkomukset</h2>

            <p>
              Asiakkaan sopimusrikkomuksena pidetään Myyjän Tuotteen markkinointiin tai Tuotemerkin esilläoloon
              haitallisesti vaikuttavaa toimenpidettä, tai Tuuneri palvelun käyttöä muuhun kuin personoidun tuotteen
              luomiseen ja tilaamiseen sopimuskumppanien välillä. Sopimusrikkomuksen perusteella Myyjällä on oikeus
              periä takaisin kaikki tuet ja alennukset, jotka on myönnetty Asiakkaalle ennakkoon. Sama koskee myös
              kaikkia Asiakkaalle ennakkoon toimitettuja Tuotteita. Sopimusrikkomuksen perusteella Myyjällä on oikeus
              purkaa sopimus päättymään välittömästi, mikäli rikkomusta ei ole korjattu siihen mennessä, kun kymmenen
              ( 10 ) päivää on kulunut siitä, kun Asiakkaalle on annettu muistutus. Asiakas vastaa Tuuneriin Oyj:n ja/tai
              Polarmonks Oy:n kaikista vahingoista sekä kuluista, joita Tuuneriin Oyj:lle ja/tai Polarmonks Oy:lle aiheutuu
              Asiakkaan haitallisesta toiminnasta, Asiakkaan toimittamasta materiaalista tai sisällöstä johtuen taikka
              siitä, että se loukkaa hyvää tapaa tai kolmannen oikeuksia.
            </p>

            <h2>8. Sopimuksen päättäminen ja irtisanominen</h2>

            <p>
              Tuuneriin Oyj ja/tai Polarmonks Oy pidättää oikeuden päättää asiakkaan oikeudesta tilata tuotteita Tuuneri
              palvelun kautta.
            </p>

            <h2>9. Virhe</h2>

            <p>
              Asiakkaan on huolellisesti tarkastettava Tuote vastaanoton yhteydessä. Jos Tuote on joltakin osin
              virheellinen, eikä sitä virheen johdosta voida käyttää tai hyödyntää tarkoitetulla tavalla, Tuotteen
              virheeksi ei katsota värieroja joita varsinaisessa tuotteessa saattaa ilmetä Asiakkaan näyttöön nähden.
              Asiakkaan tulee kirjallisesti ilmoittaa virheestä välittömästi ja viimeistään viiden ( 5 ) päivän kuluttua
              toimituspäivästä tai siitä kun virhe olisi pitänyt havaita. Edellyttäen että virheestä on ilmoitettu tämän
              mukaisesti, Myyjällä on harkintansa mukaan oikeus korjata, vaihtaa tai hyvittää virheelliseksi toteamansa
              Tuote tai sen osa. Myyjän pyynnöstä Asiakas on velvollinen toimittamaan virheellisen Tuotteen tai näytteen
              siitä Myyjälle tämän tarkastettavaksi. Myyjä ei ole velvollinen suorittamaan Asiakkaalle korvausta
              välillisestä tai epäsuorasta vahingosta. Asiakkaalla ei ole tilaamiensa tuotteiden peruutus- eikä
              palautusoikeutta.
            </p>

            <h2>10. Ylivoimainen este</h2>

            <p>
              Myyjä ei ole velvollinen täyttämään sopimusta, jos jokin seuraavista seikoista estää sopimuksen
              täyttämisen tai tekee sen kohtuuttoman vaikeaksi: lakko, työsulku, tulipalo, sota, liikekannallepano,
              vienti- ja tuontikielto, kuljetusvälineiden puute, valmistuksen lopettaminen, yleinen tavaran niukkuus,
              liikennehäiriö tai muu sellainen este, johon Myyjä ei voi vaikuttaa. Myös silloin kun sopimuksen
              täyttäminen edellyttäisi uhrauksia, jotka ovat kohtuuttomia verrattuna Asiakkaalle siitä koituvaan etuun,
              Myyjä ei ole velvollinen täyttämään sopimusta. Myyjä ei ole velvollinen korvaamaan Asiakkaalle sopimuksen
              täyttämättä jättämisestä aiheutunutta vahinkoa ja hän voi myös purkaa sopimuksen.
            </p>

            <h2>11. Luottamuksellisuus</h2>

            <p>
              Kaikki sopimuksen perusteella tehtävien suoritusten määrät ja muut tiedot ovat luonteeltaan
              liikesalaisuuksia, eivätkä osapuolet saa niitä luvatta antaa sivullisten tietoon.
            </p>

            <h2>12. Muutokset</h2>

            <p>Kaikki muutokset osapuolten väliseen sopimukseen tehdään kirjallisesti.</p>

            <h2>13. Erimielisyydet</h2>

            <p>
              Tästä sopimuksesta mahdollisesti aiheutuvat erimielisyydet on aina ensisijaisesti pyrittävä ratkaisemaan
              neuvotteluteitse. Helsingin käräjäoikeus on toimivaltainen tuomioistuin riidanratkaisussa.
            </p>

            <h2>14. Immateriaalioikeudet</h2>

            <p>
              Palvelun sisällön kaikki oikeudet kuuluvat Tuuneriin Oyj:lle ja/tai Polarmonks Oy:lle. Sivustolla oleva
              materiaali (kuten tekstit, kuvat, logot, tavaramerkit ja muu suojattu aineisto) on Tuuneriin Oyj:n tai
              Polarmonks Oy:n omaisuutta. Materiaalien kopioiminen tai käyttö ilman Tuuneriin Oyj:n ja/tai Polarmonks
              Oy:n lupaa on kielletty.
            </p>

            <h2>15. Asiakasrekisteri</h2>

            <p>Palvelu kerää tilaajien asiakasrekisteriä</p>

            <p class="has-text-weight-bold">Rekisterin nimi ”Tuuneri verkkokauppa”</p>
            <p class="has-text-weight-bold">Henkilötietojen käsittelyn tarkoitus</p>
            <ol type="a">
              <li>
                Henkilötietoja käsitellään tarkoituksissa, jotka liittyvät asiakassuhteen hoitamiseen, hallinnointiin
                ja kehittämiseen, palvelujen tarjoamiseen ja toimittamiseen sekä palvelujen kehittämiseen ja
                laskutukseen liittyen. Henkilötietoja käsitellään myös mahdollisten reklamaatioiden ja muiden
                vaatimusten selvittämisen edellyttämissä tarkoituksissa.
              </li>
              <li>
                Lisäksi henkilötietoja käsitellään asiakkaille suunnatussa viestinnässä kuten tiedotus- ja
                uutisointitarkoituksissa sekä markkinoinnissa, jonka osana henkilötietoja käsitellään myös
                suoramarkkinointiin ja sähköiseen suoramarkkinointiin liittyvissä tarkoituksissa.
              </li>
              <li>
                Asiakkaalla on oikeus kieltää hänelle kohdistettu suoramarkkinointi. Rekisterinpitäjä käsittelee tietoja
                itse sekä hyödyntää henkilötietojen käsittelyssä rekisterinpitäjän puolesta ja lukuun toimivia
                alihankkijoita.
              </li>
              <ul class="mt-0">
                <li>Tarkemmat rekisteritiedot löytyvät <a @click="$refs.register.closeModal(); $refs.register.showModal()">tästä</a></li>
                <register-modal ref="register"></register-modal>
              </ul>
            </ol>

            <h2>16. Tilauksen hyväksyntä sekä mahdollinen peruuntuminen</h2>

            <p>
              Myyjä tarkastaa asiakkaan tilauksen ja pidättää oikeuden perua tilauksen seuraavien määräysten mukaisesti.
              Asiakas on velvollinen huomioimaan, että Palvelussa huomioidaan alkoholilain 50 §:n määräykset, eli
              alkoholijuomien osalta Palvelu eikä tuotteen visuaalinen ilme teksteineen saa kohdistua:
            </p>
            <ol>
              <li>alaikäisiin</li>
              <li>korostaa alkoholipitoisuutta myönteisenä ominaisuutena,</li>
              <li>kuvata runsasta alkoholin käyttöä myönteisesti,</li>
              <li>luoda kuvaa suorituskyvyn lisääntymisestä,</li>
              <li>luoda kuvaa sosiaalisesta tai seksuaalisesta menestymisestä,</li>
              <li>luoda kuvaa lääketieteellisistä tai terapeuttisista vaikutuksista,</li>
              <li>luoda kuvaa, että alkoholi piristää, rauhoittaa tai on keino ristiriitojen ratkaisemiseksi,</li>
              <li>olla hyvän tavan vastaista,</li>
              <li>yhdistää alkoholin käyttöä ajoneuvolla ajamiseen,</li>
            </ol>

            <p>
              Palvelu tai sen kautta toteutettu pakkaus ei saa myöskään olla ketään loukkaava tai syrjivä iän,
              sukupuolen, alkuperän, kansalaisuuden, kielen, uskonnon, vakaumuksen, mielipiteen, poliittisen toiminnan,
              ammattiyhdistystoiminnan, perhesuhteiden, terveydentilan, vammaisuuden, seksuaalisen suuntautumisen tai
              muun henkilöön liittyvän syyn perusteella.
            </p>

            <p>
              Asiakas vastaa tuotteeseen lataamiensa kuvien sekä tekstien tekijänoikeuksista. Muiden omistamaa
              tekijänoikeudellisuuden piiriin kuuluvia kuvia eikä tekstejä saa käyttää ilman niiden omistajien
              virallista lupaa.
            </p>

            <h2>17. Vastuunrajoitus</h2>

            <p>
              Tuuneriin Oyj sekä Polarmonks Oy eivät takaa palvelun häiriötöntä toimintaa. Palvelua koskevat samat
              rajoitukset kuin Internetiä yleensä, esimerkiksi käyttäjien yhtäaikaiset yhteydenotot voivat ruuhkauttaa
              palvelua ja vaikuttaa sen käytettävyyteen. Tuuneriin Oyj sekä Polarmonks Oy ei vastaa siitä, jos Asiakkaan
              tilaus ei tule perille tietoverkon tai tietoliikenneyhteyksien toimimattomuuden vuoksi. Tuuneriin Oyj sekä
              Polarmonks Oy ei vastaa ylivoimaisen esteen (Force majeure) aiheuttamista viivästymisistä. Lisäksi Tuuneriin
              Oyj sekä Polarmonks Oy ei ole vastuussa kuljetuksissa tapahtuvista virheistä. Tuuneriin Oyj sekä Polarmonks
              Oy ei vastaa välillisistä vahingoista. Tuuneriin Oyj sekä Polarmonks Oy:n vastuu rajoittuu kaikissa tapauksissa
              virheellisten/viivästyneiden/puuttuneiden tuotteiden arvoon.
            </p>

            <h2>18. Palveluntarjoaja</h2>

            <p>Tuuneriin Oyj</p>

            <h2>19. Verkkokaupan ylläpitäjä</h2>

            <p>Tuuneri Verkkokaupan asiakaspalvelu</p>
            <p>Polarmonks Oy (Y-tunnus 3151822-4)</p>
            <p>Sähköposti: info@polarmonks.fi</p>
            <p>Puh. 044-3440382</p>

          </div>
      </section>
      <footer class="modal-card-foot is-justify-content-center">
        <button class="button is-primary is-rounded blue-button" @click="closeModal()">
          <span class="has-text-black">OK</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>

import registerModal from "@/components/registerModal";

export default {
  name: 'termsModal',
  data() {
    return {
      active: false
    }
  },
  components: {
    registerModal,
  },
  methods: {
    closeModal(){
      this.active = false;
    },
    showModal(){
      this.active = true;
    }
  }
}
</script>
<style type="scss" scoped>

.modal-card {
  width: 60%;
  border-radius: 30px;
}

.content {
  text-align: left;
}

.modal-card-head {
  height: auto;
  border-radius: 30px 30px 0 0;
}

h2 {
  color: black;
}

p {
  color: black;
}

li {
  color: black;
  font-family: 'Avenir';
}

a {
  color: black;
  text-decoration: underline;
}
</style>
