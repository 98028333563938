<template>
  <div class="home">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <HeroHome/>
        </div>
        <div class="column is-12 pt-0">
          <ColumnHome/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColumnHome from '../components/ColumnHome'
import HeroHome from '../components/HeroHome'
import { mapMutations } from 'vuex'

export default {
  name: 'Home',
  components: {
    HeroHome,
    ColumnHome,
  },
  data() {
    return {}
  },
  async mounted() {
    this.resetData()
  },
  methods: {
    ...mapMutations({
      resetData: 'resetData'
    })
  }
}
</script>

<style lang="scss">

.button-margin {
  margin-top: -25px;
}

.button-marginb {
  margin-bottom: -25px;
}

.blue-button {
  background: #89e2f9 !important;
  border: 2px solid white !important;
}

.container {
  max-width: 1000px !important;
}

.page-setting {
  .container {
    max-width: 100% !important;
  }
}

.home {
  background-color: #eeeeee;
  @media (max-width: 768px) {
    background-color: white;
  }
}

.slick-dots {
  display: block;
  width: 89px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  li {
    float: left;
    margin-right: 10px;
    button {
      border-radius: 10px;
      color: #eeeeee;
      background-color: #eeeeee;
      border: 0px;
    }
    button:hover {
      cursor: pointer;
    }
  }
}
</style>
