<template>
  <div class="modal" :class="{'is-active': active}">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <div class="has-text-centered py-2 pl-6 ml-4 is-invisible">
          <span>
            <img alt="background" src="../assets/Tuunerilogonav.svg" svg-inline svg-sprite/>
          </span>
        </div>
        <!-- <button class="delete" aria-label="close"></button> -->
      </header>
      <section class="modal-card-body has-text-centered">
          <!-- Content ... -->
          <div class="content pb-6">
            <h2>Ohjeita etiketin suunnitteluun. </h2>

            <p>
              Valittavanasi on valmiita etikettipohjia tai vaihtoehtoisesti sellaisia, joihin
              voit ladata oman kuvan tai vaikka yrityksen logon. Lähes kaikkiin etiketteihin
              voit laittaa myös oman tekstin, myös etiketin takaa löytyvän taustatarinan voi
              muokata omanlaiseksi. Rajotteena teksteissä on kussakin palkissa määritetyt
              merkkimäärät.
            </p>

            <ul>
              <li>
                Taustaetikettiä pääsee katsomaan pullon vieressä sijaitsevasta valintanäppäimestä.
              </li>

              <li>
                Etikettimalleja voi vaihdella pullon vieressä sijaitsevista nuolinäppäimistä.
              </li>

              <li>
                Itse suunnitellut etiketit soveltuvat erinomaisesti tapahtuman- tai yrityksen
                markkinointiin, erilaisiin tapahtumiin ja teemoihin sekä saamaan hienosta
                hetkestä entistä täydellisemmän.
              </li>
            </ul>

            <p>
              Huomioithan, että toimitusehdoissa esitettyjä sääntöjen vastaisia kuvia ja tekstejä
              ei hyväksytä!
            </p>

            <p>
              Kuviksi soveltuu erinomaisesti valokuvat tai muu valmiiksi tuotettu graafinen aineisto.
              Suurin tiedostonkoko 20Mb Tuetut tiedostotyypit: jpg, tiff, png.
            </p>

            <ul>
              <li>
                Huomioithan että sinulla tulee olla oikeus käyttää etiketteihin lataamiasi kuvia.
              </li>
            </ul>

            <p>
              Ei muuta kun rohkeasti inspiroitumaan ja kokeilemaan erilaisia versioita Tuunerilla.
            </p>

          </div>
      </section>
      <footer class="modal-card-foot is-justify-content-center">
        <button class="button is-primary is-rounded blue-button" @click="closeModal()">
          <span class="has-text-black">OK</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: 'infoModal',
  data() {
    return {
      active: false
    }
  },
  methods: {
    closeModal(){
      this.active = false;
    },
    showModal(){
      this.active = true;
    }
  }
}
</script>
<style type="scss" scoped>

.modal-card {
  width: 60%;
  border-radius: 30px;
}

.content {
  text-align: left;
}

.modal-card-head {
  height: auto;
  border-radius: 30px 30px 0 0;
}

h2 {
  color: black;
}

p {
  color: black;
}

li {
  color: black;
  font-family: 'Avenir';
}

a {
  color: black;
  text-decoration: underline;
}
</style>
