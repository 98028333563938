<template>
  <section class="hero background-color">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-10 is-offset-1 is-12-mobile">
            <div class="round-div">
              <div>
                <div class="round-divcolor has-text-centered py-3 px-3 is-invisible">
                  <span>
                    <img alt="icon" src="../assets/TuunerilogoSignIn.svg" svg-inline svg-sprite />
                  </span>
                </div>
                <div class="company-box-padding columns pt-2 mt-2">
                  <div class="column is-6 is-12-mobile">
                    <div class="field">
                      <h2 class="has-text-black text-heading">Kirjaudu sisään</h2>
                      <p class="mb-2 has-text-weight-bold">
                        Miten saan tilausnumeron?
                      </p>
                      <p class="mb-2">
                        Löydät tarkemmat tilausohjeet viereisestä ikkunasta.
                      </p>
                      <!--div class="control flex-column">
                        <h3 class="has-text-black text-heading-small pb-2">Tilausnumero</h3>
                        <input v-model="contact.order_number" class="input" placeholder="" type="text" maxlength="7"/>
                        <p v-if="$v.contact.order_number.$error || order_number_error" class="help is-danger">Tilausnumero on väärin</p>
                      </div-->
                      <label class="checkbox pt-4 pb-4">
                        <div class="flex-row">
                          <input v-model="contact.terms" class="checkbox-size" type="checkbox" />
                          <span class="pl-2 has-text-black text-size-small pt-1">
                            Hyväksyn palvelun <a @click="$refs.terms.closeModal(); $refs.terms.showModal()" class="has-text-black">ehdot</a>
                          </span>
                          <terms-modal ref="terms"></terms-modal>
                        </div>
                        <p v-if="noterms" class="help is-danger">Tämä kenttä on pakollinen</p>
                      </label>
                      <div class="py-4">
                        <div class="flex-row space-between">
                          <button class="button is-rounded is-outlined is-warning has-background-white white-bg" @click="$router.push('/')">
                            <span class="icon">
                              <img alt="icon" class="icon" src="../assets/ArrowCircleLeft.svg" svg-inline svg-sprite />
                            </span>
                            <span>Takaisin</span>
                          </button>
                          <button class="button btncolor is-warning is-rounded has-background-warning is-outlined" @click="goToEditor()">
                            <div class="flex-row space-between">
                              <span class="has-text-white">Seuraava</span>
                              <span class="icon">
                                <img
                                  alt="icon"
                                  class="icon"
                                  src="../assets/ArrowCircleRight.svg"
                                  svg-inline
                                  svg-sprite
                                />
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <!-- <div class="pt-4">
                        <div class="flex-row space-between">
                          <p class="warning-message">Huom. Poikkeuksellisesti seuraava toimitus on keskiviikkona 3.8. Iltapäivästä!</p>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="column is-6 is-12-mobile info-column">
                    <div class="field">
                      <h2 class="has-text-black text-heading">Näin teet tilauksen:</h2>
                      <p class="mb-2">
                      1. Tilaukseen tarvitaan tilausnumero.
                      </p>
                      <p class="mb-2">
                      2. Tee noutotilaus haluamallesi määrälle, haluamaasi vapaaseen noutoikkunaan, seuraavin ehdoin:
                         kaikki viimeistään kuluvan viikon sunnuntain aikana tehdyt tilaukset ovat noudettavissa
                         puolentoista viikon päästä keskiviikkona, iltapäivällä. <b>Seuraava noutopäivä on keskiviikko
                         {{deliveryDay}}</b> (<span class="is-size-7">tilaukset vahvistetaan tuotantoon kerran viikossa,
                         maanantaisin</span>). Citymarket Kupittaa pidättää oikeuden poistaa harkintansa mukaan kaikki
                         muut, ylimääräiset tuotteet tilaukselta.
                      <p class="mb-2">
                      3. Onnistuneesta tilauksesta saat sähköpostiisi tilausvahvistuksen, josta löytyy tilauksen
                         tiedoista ’Tilausnumero’ -kohdasta 7-numeroinen tilausnumero.
                      </p>
                      <p class="mb-2">
                      4. Syötä tilausnumero Tuuneri-palvelun sisäänkirjautumisikkunan Tilausnumero -kenttään.
                         Numeroiden on vastattava toisiaan, jotta tilaus käsitellään.
                      </p>
                      <p class="mb-2">
                      5. Tee tilaus loppuun Tuuneri -palvelun ohjeiden ja käyttöehtojen mukaisesti. Tilatun määrän
                         on vastattava noutotilauksen tilattua määrää, jotta tilaus käsitellään.
                      </p>
                      <p class="mb-2">
                      6. Kupittaan verkkokauppa vahvistaa ja veloittaa tilauksen, minkä jälkeen tilaus siirtyy
                         tuotantoon. Saat maksusta kuitin sähköpostiin.
                      </p>
                      <p class="mb-2">
                      7. Nouda tilaus Citymarket Kupittaan noutopisteestä sovittuna aikana. Tarkemmat nouto-ohjeet saat
                        sähköpostiisi, kun tilaus on hyväksytty tuotantoon. Lähetämme sinulle myös tekstiviestin heti,
                        kun tilaus on noudettavissa Kupittaalta.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
</template>
<script>

import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import termsModal from "@/components/termsModal";

export default {
  name: 'CompanyInfo',
  data() {
    return {
      noterms: false,
      order_number_error: false,
      contact: {
        company_name: null,
        customer_id: null,
        order_number: null,
        terms: false
      }
    }
  },
  components: {
    termsModal
  },
  mounted() {
    this.contact = this.$store.getters.getCustomer
  },
  validations() {
    return {
      contact: {
        //order_number: {
        //  required,
        //},
        customer_id: {},
        company_name: {}
      }
    }
  },
  computed: {
    deliveryDay() {
      function getMonday(d) {
        d = new Date(d)
        let day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff))
      }

      let date = getMonday(new Date())
      date.setDate(date.getDate() + 16);

      const monthNames = [ "Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu",
        "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"
      ];
      return date.getDate() + " " + monthNames[date.getMonth()]
    },
  },
  methods: {
    ...mapActions({
       postCustomer: 'postCustomer',
       postOrder: 'postOrder'
    }),
    ...mapGetters({
      getOrderUuid: 'getOrderUuid'
    }),
    goToEditor() {
      console.log("go to editor")
      let self = this
      this.order_number_error = false

      if (!this.contact.terms) {
        this.noterms = true
        return
      } else {
        this.noterms = false
      }

      this.$v.$touch()

      if (this.$v.$error) return

      this.postCustomer(this.contact).then(() => {
        this.$v.$reset()
        this.$router.push({name: 'Label', params: {uuid: self.getOrderUuid()}})
      })
      .catch((error) => {
        self.order_number_error = true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/* orange */
.background-color {
  background-color: #282c43;
  min-height: 700px;
}

.hero {
  @media (max-width: 770px) {
    height: 100%;
  }
  height: calc(100vh - 124px);

  .hero-body {
    display: flex;
    align-items: center;
  }
  .container {
    max-width: 1000px !important;
  }
}

.round-div {
  background-color: #ffffff;
  height: fit-content;
  //width: 550px;
  border-radius: 30px 30px 30px 30px;
}

.round-divcolor {
  background-color: #89e2f9;
  height: auto;
  border-radius: 30px 30px 0px 0px;
}

.flexend {
  display: flex;
  justify-content: flex-end;
}

.btncolor {
  background: #e9721f;
}

.checkbox-size {
  height: 25px;
  width: 25px;
  background-color: rgb(238, 20, 20);
}

a {
  text-decoration: underline;
}

.company-box-padding {

  @media (max-width: 770px) {
    height: 100%;
  }

  @media (max-width: 400px) {
    padding: 0.5rem !important;
  }
  padding: 3rem;
  height: 500px;
}

.info-column {
  overflow: hidden;
  overflow-y: scroll;
}

</style>
