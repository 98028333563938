<template>
  <div class="bg-color-nav">
    <div class="bg-color">
      <nav class="navbar is-transparent bg-color is-hidden-touch">
        <div class="navbar-menu">
          <div class="navbar-start nav-setting">
            <a class="navbar-item navbar-item-no-pointer padding">
              <span class="icon is-large">
                <img alt="icon" class="icon" :src="icon" svg-inline svg-sprite/>
              </span>
            </a>
            <a class="navbar-item navbar-item-no-pointer"><h1 class="txt-color"><b>{{ heading }}</b></h1></a>
          </div>
          <div class="navbar-end is-invisible">
            <div class="navbar-item">
              <a class="navbar-item navbar-item-no-pointer is-hidden-desktop-only is-hidden-mobile">
                <span>
                  <img alt="icon" src="../assets/Tuunerilogo.svg" style="width:100px; height:100px;" svg-inline svg-sprite/>
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <nav class="navbar is-transparent bg-color-mobile is-hidden-desktop">
        <div class="navbar-menu is-active pb-0">
          <div class="navbar-start nav-setting">
            <div class="container mobile-navbar-container">
              <div class="columns is-mobile">
                <div class="mobile-navbar-rounded column is-6 pb-0 pr-0">
                  <a class="navbar-item navbar-item-no-pointer mobile-navbar-header"><h3 class="has-text-white"><b>{{ heading }}</b></h3></a>
                </div>
                <div class="column is-2 pb-0 pl-0 pr-0">
                  <div class="navbar-item mobile-top-navbar" :class="{'is-active': this.active === 'order'}">
                    <a v-on:click="orderNavigation()">
                      <span class="icon is-large is-hidden-mobile">
                        <img alt="icon" class="icon" src="../assets/ShoppingCartWhite.svg" svg-inline svg-sprite/>
                      </span>
                      <span class="icon is-medium is-hidden-tablet">
                        <img alt="icon" class="icon" src="../assets/ShoppingCartWhite.svg" svg-inline svg-sprite/>
                      </span>
                    </a>
                  </div>
                </div>
                <div class="column is-2 pb-0 pl-0 pr-0">
                  <div class="navbar-item mobile-top-navbar" :class="{'is-active': this.active === 'summary'}">
                    <a v-on:click="summaryNavigation()">
                      <span class="icon is-large is-hidden-mobile">
                        <img alt="icon" class="icon" src="../assets/Receipt.svg" svg-inline svg-sprite/>
                      </span>
                      <span class="icon is-medium is-hidden-tablet">
                        <img alt="icon" class="icon" src="../assets/Receipt.svg" svg-inline svg-sprite/>
                      </span>
                    </a>
                  </div>
                </div>
                <div class="column is-2 pb-0 pl-0 pr-0">
                  <div class="navbar-item mobile-top-navbar">
                    <a href="#">
                      <span class="icon is-large is-hidden-mobile">
                        <img alt="icon" class="icon" src="../assets/Package.svg" svg-inline svg-sprite/>
                      </span>
                      <span class="icon is-medium is-hidden-tablet">
                        <img alt="icon" class="icon" src="../assets/Package.svg" svg-inline svg-sprite/>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex"

let mapping = {
  'label': {
    'heading': 'Etiketin suunnittelu',
    'icon': require('../assets/PencilCircle.svg')
  },
  'order': {
    'heading': 'Ostoskori',
    'icon': require('../assets/ShoppingCartWhite.svg')
  },
  'summary': {
    'heading': 'Maksu',
    'icon': require('../assets/Receipt.svg')
  },
}

export default {
  name: 'LeftMenuHeader',
  computed: {
    ...mapState(['labelsData', 'postContactData']),
    ...mapGetters(['getOrderUuid']),
    heading() {
      return mapping[this.type]['heading']
    },
    icon() {
      return mapping[this.type]['icon']
    }
  },
  methods: {
    orderNavigation() {
      if (this.labelsData[0].uuid) {
        this.$router.push({ name: 'Order', params: {uuid: this.getOrderUuid} })
      }
    },
    summaryNavigation() {
      if (this.postContactData) {
        this.$router.push({ name: 'Summary' })
      }
    },
  },
  props: {
    type: {
      default: 'label'
    },
    active: {
      default: 'label'
    }
  }
}
</script>

<style lang="scss" scoped>

.nav-setting {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bg-color {
  background-color: #89e2f9;
  height: 80px;
  border-radius: 0px 30px 0px 0px;
}

.bg-color-mobile {
  height: 80px;
  background-color: #89e2f9;
  .mobile-navbar-rounded {
    background-color: #89e2f9;
    border-radius: 0px 30px 0px 0px;
    height: 86px;
  }
}

.bg-color-nav {
  background-color: white;
  height: 80px;
}

.txt-color {
  color: #fff;
  font: 2em sans-serif;
}

.padding {
  padding-left: 100px;
}

.navbar-item-no-pointer:hover {
  cursor: auto;
}

.mobile-top-navbar {
  color: #00ffff;
  margin: 5px;
  background-color: #282c43;
  border-radius: 50%;
  width: 70px;
  @media (max-width: 768px) {
    width: 57px;
  }
  @media (max-width: 400px) {
    width: 40px;
    height: 36px;
  }
  @media (max-width: 400px) {
    .icon.is-medium {
      height: 1rem;
      width: 1rem;
    }
  }
}

.mobile-navbar-container {
  height: 80px;
  border-bottom: 6px solid #89E2F9;
}

.mobile-navbar-first-icon {
  height: 86px;
  padding-top: 23px;
}

.mobile-navbar-header {
  padding-top: 16px;
  h3 {
    font-size: 20px;
    @media (max-width: 400px) {
      font-size: 18px;
    }
  }
}

.navbar.bg-color {
  position: initial;
}

.navbar.bg-color-mobile {
  position: relative;
  top: -15px;
}

</style>
