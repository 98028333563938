<template>
  <section class="thanks-section" style="min-height: calc(100vh - 124px)">
    <section class="bg-color">
      <div class="hero-body">
        <div class="columns ">
          <div class="column is-4 is-offset-4">
            <div class="container has-text-centered pt-4 is-invisible">
              <figure class="image is-6by1 pl-6 pr-6">
                <img alt="Placeholder image" src="../assets/Tuunerilogobig.svg" width="1px"/>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-medium has-background-white">
      <div class="hero-body">
        <div class="content-wrapper columns is-centered mb-4">
          <div class="column is-4 has-text-centered">
            <div class="media-content">
              <h1 class="textheading is-4 has-text-black"><b>Kiitos tilauksesta!</b></h1>
            </div>
            <div class="pl-6 pr-6 mt-5 mb-6">
              <p class="text-size-small has-text-black">
                Tilausvahvistus on lähetetty sähköpostiisi.
              </p>
            </div>
            <div class="is-flex is-justify-content-center mt-4 ">
              <button class="button is-primary is-rounded blue-button wider-button">
                <span class="has-text-black" @click="$router.push('/')">Takaisin aloitussivulle</span>
              </button>
            </div>
          </div>
        </div>
      </div>
</section>
  </section>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ThankPage',
  created() {
    if (!this.updateOrderData || this.updateOrderData.status !== 'ORDERED' )
      this.$router.push('/')
    else if (this.updateOrderData.status !== 'ORDERED') {
      this.$router.push({name: 'label'})
    }
  },
  computed: {
    ...mapState(['updateOrderData'])
  }
}
</script>

<style lang="scss" scoped>

.thanks-section {
  background-color: white;
  .bg-color {
    background-color: white;
  }
}

.hero-body {
  .content-wrapper {
    width: 100%;
  }
}

</style>