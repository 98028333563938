/*
Good article what this is about -> https://medium.com/@lachlanmiller_52885/a-pattern-to-handle-ajax-requests-in-vuex-2d69bc2f8984
 */

// src/async-util.js
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_OLVI_BACKEND_URL

const doAsync = (store, {url, mutationTypes, method = 'get', data = {}}) => {
  store.commit(mutationTypes.FAILURE, null) // remove previous failure
  store.commit(mutationTypes.PENDING)
  let config = {
    method: method,
    data: data
  };
  return new Promise((resolve, reject) => {
    axios(url, config)
      .then(response => {
        store.commit(mutationTypes.SUCCESS, response.data)
        resolve(response.data)
      })
      .catch(error => {
        let data = error.response ? error.response.data : {};
        store.commit(mutationTypes.FAILURE, data)
        reject(error)
      })
  })
}
export default doAsync
