<template>
  <div class="bg-color">
    <SpinnerModal :spinning="apiCallRunnin"></SpinnerModal>
    <section class="hero bg-color is-fullheight-with-navbar">
      <div class="hero-body">
        <div class="container pl-3 pr-3 pt-1 pb-1">
          <div class="p-3">
            <div class="field pt-4">
              <h3 class="text-heading-small has-text-white">Maksu ja tilausvahvistus</h3>
              <p class=" text-size-small has-text-white pt-4">
                Lähetämme sinulle toimitusvahvisteen {{customerEmail}}, kun tuotteiden maksu on onnistuneesti varmistettu valitun määrän mukaisesti Citymarket kupittaan verkkokaupassa.
              </p>
            </div>
          </div>
          <OrderSummaryComponent :hide-counter="true"/>
          <div class="pt-4">
            <div class="flex-row space-between">
              <button class="button is-rounded is-outlined is-warning has-background-white white-bg" @click="goToOrder()">
                <span class="icon">
                  <img alt="icon" class="icon" src="../assets/ArrowCircleLeft.svg" svg-inline svg-sprite />
                </span>
                <span>Edellinen</span>
              </button>
              <button class="button btncolor is-rounded" @click="payOrder()">
                <span class="has-text-white">Tee tilaus</span>
                <span class="icon pt-1">
                  <img alt="icon" class="icon" src="../assets/Package.svg" svg-inline svg-sprite/>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex"
import OrderSummaryComponent from './OrderSummaryComponent'
import SpinnerModal from './SpinnerModal'

export default {
  name: 'SummaryLeftPage',
  data() {
    return {
      apiCallRunnin: false
    }
  },
  components: {
    OrderSummaryComponent,
    SpinnerModal
  },
  mounted() {
    if (!this.postContactData)
      this.$router.push({name: 'label'})
  },
  methods: {
    ...mapActions({
      updateOrder: 'updateOrder',
      updateLabelsAmount: 'updateLabelsAmount'
    }),
    ...mapGetters({
      getOrderUuid: 'getOrderUuid'
    }),
    payOrder() {
      this.apiCallRunnin = true
      this.updateLabelsAmount()
      this.updateOrder({'status' : 'ORDERED'}).then(() => {
        this.apiCallRunnin = false
        this.$router.push({name: 'ThankYou'})
      })
    },
    goToOrder() {
      this.$router.push({name: 'Order', params: {uuid: this.getOrderUuid()}})
    }
  },
  computed: {
     ...mapState(['postContactData']),
    amount() {
       return this.$store.state.postOrderData.amount
    },
    customerEmail() {
      if (this.$store.state.postContactData)
        return this.$store.state.postContactData.cust_email
    }
  }
}
</script>

<style lang="scss">


.border {
  border-top: 2px solid rgb(33, 211, 255);
}

.border-black {
  border-top: 12px solid rgb(0, 0, 0);
}

.border-dot {
  border-top: 3px dotted rgb(0, 0, 0);
}

.selects {
  background-color: DodgerBlue;
}

.btncolor {
  background: #e9721f !important;
}

.checkboxsize {
  height: 25px;
  width: 25px;
  background-color: rgb(238, 20, 20);
}
</style>
