import Vue from "vue";
import store from '@/store'
import Router from "vue-router";
import Home from '@/views/Home'
import LabelOption from '@/views/LabelOption'
import Order from '@/views/Order'
import Summary from '@/views/Summary'
import SignIn from '@/views/SignIn'
import ThankYou from '@/views/ThankYou'
import {waitForStorageToBeReady} from "@/utils"

Vue.use(Router);

var hash = location.hash;

if (hash && hash.indexOf('#!') === 0) {
  location.hash = hash.slice(2);
}

const router = new Router({
    //mode: 'history',
    //base: '/#!/',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/:uuid/label',
            name: 'Label',
            component: LabelOption,
            meta: {requiresAuth: true}
        },
        {
            path: '/:uuid/order',
            name: 'Order',
            component: Order,
            meta: {requiresAuth: true}
        },
        {
            path: '/summary',
            name: 'Summary',
            component: Summary,
            meta: {requiresAuth: true}
        },
        {
            path: '/signin',
            name: 'SignIn',
            component: SignIn,
        },
        {
            path: '/thanks',
            name: 'ThankYou',
            component: ThankYou,
            meta: {requiresAuth: true}
        }
    ],
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    }
});

router.beforeEach(async (to, from, next) => {
  await waitForStorageToBeReady()
  if (to.matched.some(record => record.meta.requiresAuth)) {
      // let's check if we need to re initialize the editor
      if (to.name == 'Order' && to.query.edit == 'true') {
          store.dispatch('getOrderAndCustomer', {'uuid': to.params.uuid}).then(() => {
              next()
          })
      } else if (!store.getters.authToken) {
          next({
              path: '/signin',
              query: {
                  redirect: 'label',
              },
          });
      } else {
          next();
      }
  }
  else {
      next();
  }
});

export default router;
