<template>
  <section class="hero bg-color">
    <div class="hero-body is-paddingless">
      <div class="container p-4" v-if="getPricingData && getShippingPriceData">
        <div class="flex-row order-item mb-2" v-for="(label, idx) in labelsData" :key="label.uuid">
          <div class="column is-2 is-flex is-align-items-center">
            <input :checked="idx === selectedCartItem" class="checkbox-size mr-2 pointer" type="checkbox" @click="setCartItem(idx)" />
            <div class="order-label" v-html="svgs[label.uuid]">
            </div>
          </div>
          <div class="column is-4">
            <strong class="is-size-4 has-text-weight-bold has-text-white">Etiketti #{{ idx + 1 }}</strong>
            <div class="actions is-flex is-paddingless">
              <span class="icon mr-1 is-clickable mr-6 mt-3" @click="editLabel(idx)">
                <img alt="icon" class="icon pointer" src="../assets/Edit.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-clickable mt-3" @click="deleteLabel(idx)">
                <img alt="icon" class="icon" src="../assets/Delete.svg" svg-inline svg-sprite/>
              </span>
            </div>
          </div>
          <div class="column is-4">
            <strong class="is-size-4 has-text-weight-bold has-text-white">Laatikoita: </strong>
            <div class="my-2">
              <input type="number" min="1" v-model.number="label.amount" @input="(event) => changeLabelAmount(event.target.value, idx)"
                     class="input has-text-centered amount-input is-paddingless"/>
            </div>
            <div>
              <small class="has-text-white">1 laatikko = 24 pulloa</small>
            </div>
          </div>
          <!--div class="column is-3">
            <strong class="is-size-4 has-text-weight-bold has-text-white">Price:</strong>
            <div class="order-price my-2">
              <strong class="is-size-4 has-text-weight-bold has-text-white">{{ calculateSinglePrice(label.amount) }}€</strong>
            </div>
            <div>
              <small class="has-text-white">1 box = 24 bottles</small>
            </div>
          </div-->

        </div>

        <!--div class="pricing mt-6">
          <div class="pricing-row columns m-0">
            <div class="column is-6 p-0">
              <strong class="is-size-4 has-text-weight-bold has-text-white">
                Price altogether:
              </strong>
            </div>
            <div class="column is-3 has-text-white p-0">
              {{ calculatePrice }} €
            </div>
            <div class="column is-3 p-0">
              <small class="has-text-white">{{ getVAT }}</small>
            </div>
          </div>
          <div class="pricing-row columns m-0">
            <div class="column is-6 p-0">
              <strong class="is-size-4 has-text-weight-bold has-text-white">
                Shipping:
              </strong>
            </div>
            <div class="column is-3 has-text-white p-0">
              {{ shippingPrice }}
            </div>
            <div class="column is-3 p-0">
              -
            </div>
          </div>
          <div class="pricing-row columns m-0">
            <div class="column is-6 p-0">
              <strong class="is-size-4 has-text-weight-bold has-text-white">
                Total
              </strong>
            </div>
            <div class="column is-3 has-text-white p-0">
              {{ calculateTotalPrice }} €
            </div>
            <div class="column is-3 p-0">
              <small class="has-text-white">{{ getVAT }}</small>
            </div>
          </div>

        </div-->


        <!--        <div class="flex-row pb-4 is-hidden-touch" v-if="!hideCounter">-->
<!--          <input type="number" min="1" v-model.number=amount-->
<!--                 class="input has-text-centered amount-input is-paddingless"/>-->
<!--          <label class=" text-size-small has-text-white pl-2 pt-2">* Vähimmäistilaus 1 laatikko  sis. 24 pulloa</label>-->
<!--        </div>-->
<!---->
<!--        <div class="pb-4 is-hidden-desktop" v-if="!hideCounter">-->
<!--          <div class="columns is-mobile">-->
<!--            <div class="column is-8">-->
<!--              <label class=" text-size-small has-text-white pt-2">Pulloa</label>-->
<!--            </div>-->
<!--            <div class="column is-4">-->
<!--              <input type="number" min="1" v-model.number=amount-->
<!--                     class="input has-text-centered amount-input is-paddingless"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="columns is-mobile">-->
<!--            <div class="column is-12 pt-0 has-text-right">-->
<!--              <label class="text-size-small has-text-white pl-2">* Vähimmäistilaus 1 laatikko  sis. 24 pulloa</label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!--div class="black-bar"></div>
        <div class="summary-box-top-gradient"></div>
        <div class="summary-container has-text-white">
          <div>
            <div class="flex-row space-between">
              <label class=" text-size-small has-text-black p-2">Tuuneriin III -olut omalla etiketillä {{ this.getPricing.price }} € / kpl</label>
              <label class=" text-heading-total has-text-black p-2">{{ calculatePrice }} &euro;</label>
            </div>
            <div class="border-dot">
              <div v-if="discounts != null && discounts.code" class="flex-row space-between">
                <label class=" text-size-small has-text-black p-2">Kampanjakoodi {{discounts.code}}</label>
                <label class=" text-size-small has-text-black p-2">{{ discount_value }}</label>
              </div>
              <div class="flex-row space-between">
                <label class=" text-size-small has-text-black p-2">Toimituskulut</label>
                <label class=" text-size-small has-text-black p-2">{{ shippingPrice }}</label>
              </div>
            </div>
            <div class="flex-row space-between border-solid">
              <label class="text-heading-total has-text-black p-2">Yhteensä</label>
              <label class="text-heading-total has-text-black p-2">{{ totalPrice }} &euro;</label>
            </div>
            <small class="is-6 has-text-black p-2 flexend vat-text">{{ getVAT }}</small>
          </div>
        </div-->
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex"
import axios from 'axios'

export default {
  name: 'OrderSummaryComponent',
  props: {
    hideCounter: {
      default: false
    }
  },
  data() {
    return {
      svgs: {}
    }
  },
  async created() {
    await this.getPrices()
    await this.getShippingPricing()
  },
  mounted() {
    this.labelsData.map((curr) => (this.getSvg(curr.label, curr.uuid)))
  },
  methods: {
    ...mapActions({
      updateOrder: 'updateOrder',
      getShippingPricing: 'getShippingPricing',
      getPrices: 'getPricing',
      deleteLabelAction: 'deleteLabel',
    }),
    ...mapMutations({
      changeBillAddressOption: 'changeBillAddressOption',
      setOrderAmount: 'setOrderAmount',
      setCartItem: 'setCartItem',
      setCartItemAmount: 'setCartItemAmount',
      revertFakedTaglines: 'revertFakedTaglines'
    }),
    editLabel(idx) {
      this.setCartItem(idx)
      this.revertFakedTaglines()
      this.$router.push({name: 'Label', params: {uuid: this.getOrderUuid}})
    },
    deleteLabel(idx) {
      let redirect = false
      if (this.labelsData.length === 1)
        redirect = true
      this.deleteLabelAction(idx)
      if (redirect)
        this.$router.push({name: 'Label', params: {uuid: this.getOrderUuid}})
    },
    calculateSinglePrice(amount) {
      let price = amount * this.getPricing.price
      if (this.getPricing.vat > 0) {
        let vat = this.getPricing.vat / 100
        price = price + (price * vat)
      }
      return price
    },
    changeLabelAmount(amount, idx) {
      this.setCartItemAmount({idx: idx, amount: amount ? parseInt(amount) : 1})
    },
    getSvg(url, uuid) {
      let self = this
      axios.get(url, {
        transformRequest: (data, headers) => {
          delete headers.common['Authorization']
          return data
        }})
        .then(response => {
          self.svgs = {
            ...self.svgs,
            [uuid]: response.data
              .replace('fill="url(#image)"', `fill="url(#${uuid}Image)"`)
              .replace('id="image"', `id="${uuid}Image"`)
          }
        })
    },
  },
  computed: {
    ...mapState(['getPricingData', 'getShippingPriceData', 'billAddress', 'orderAmount', 'labelsData', 'selectedCartItem']),
    ...mapGetters(['getShippingPrice', 'getPricing', 'getOrderUuid', 'getTotalLabelsAmount']),
    amount: {
      get() {
        return this.orderAmount
      },
      set(val) {
        this.setOrderAmount(val)
      }
    },
    needBillingAddress: {
      get() {
        return this.billAddress
      },
      set(newVal) {
        this.changeBillAddressOption(newVal)
      }
    },
    shippingPrice() {
      if (this.getShippingPrice == null) {
        return 'Toimitusmaksu sisältyy hintaan.'
      } else {
        return this.getShippingPrice.price + ' €'
      }
    },
    getVAT() {
      if (this.getPricing.vat == 0) {
        return 'Sisältää ALV: n'
      } else {
        return 'VAT. ' + this.$store.getters.getPricing.vat + '%'
      }
    },
    calculateTotalPrice() {
      if (this.getShippingPrice == null)
        return this.calculatePrice
      return this.calculatePrice + this.getShippingPrice.price
    },
    calculatePrice() {
      let price = this.getTotalLabelsAmount * this.getPricing.price
      if (this.getPricing.vat > 0) {
        let vat = this.getPricing.vat / 100
        price = price + (price * vat)
      }
      return price
    },
    totalPrice() {
      let price = this.amount * this.getPricing.price
      if (this.getPricing.vat > 0) {
        let vat = this.getPricing.vat / 100
        price = price + (price * vat)
      }

      // apply voucher discount if exists
      if (this.discounts) {
        if (this.discounts.amount_off != null && this.discounts.amount_off != '') {
          price -= this.discounts.amount_off * 0.01
        } else {
          price -= (price * this.discounts.percent_off) / 100
        }
      }

      return price
    },
    discounts() {
      return this.$store.getters.getVoucherDiscount
    },
    discount_value() {
      if (this.discounts.amount_off) {
        return this.discounts.amount_off * 0.01 + ' €'
      } else {
        return this.discounts.percent_off + ' %'
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.bg-color {
  background-color: #282c43;
}

.flex-set {
  display: flex;
  align-items: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  justify-content: center;
}

.border {
  border-top: 2px solid rgb(33, 211, 255);
}

.border-dot {
  border-top: 3px dotted rgb(0, 0, 0);
}

.black-bar {
  height: 15px;
  border-radius: 100px;
  background-color: black;
  width: 100%;
  position: absolute;
  left: 0px;
}

.summary-box-top-gradient {
  position: absolute;
  background: linear-gradient(180deg
    , rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  height: 34px;
  width: 94.8%;
  z-index: 20;
  @media (max-width: 1024px) {
    width: 96.8%;
  }
}

.border-solid {
  border-top: 3px solid rgb(0, 0, 0);
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.flexend {
  display: flex;
  justify-content: flex-end;
}

.selects {
  background-color: DodgerBlue;
}

.btncolor {
  background: #e9721f;
}

.checkboxsize {
  height: 25px;
  width: 25px;
  background-color: rgb(238, 20, 20);
}

.amount-input {
  max-width: 60px;
}

.vat-text {
  padding-top: 0 !important;
  font-size: 0.75rem;
  margin-bottom: 20px;
}

.order-item {
  border-bottom: 1px solid rgb(33, 211, 255);

  .order-label {
    height: 100px;
    width: 69px;
    overflow: hidden;

    >::v-deep svg {
      height: 100px;
    }
  }

  .order-price {
    height: 40px;
  }

  .actions {
    img {
      width: 18px;
      height: 18px;
    }
  }
}

</style>
