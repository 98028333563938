import wrap from 'word-wrap'
import store from '@/store/index'

const BeerLabelBaseMixin = {
  name: 'BeerLabel',

  props: {
    orderNumber: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tagline1: {
      type: String,
      default: '',
    },
    tagline2: {
      type: String,
      default: '',
    },
    tagline3: {
      type: String,
      default: '',
    },
    tagline4: {
      type: String,
      default: '',
    },
    backText: {
      type: String,
      default: '',
    },
    logo: {
      type: String,
      default: '',
    },
    mainPicture: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.updateBackText(this.backText)
  },

  methods: {
    updateBackText(val) {
      updateBackTextOnSvg(val)
    },
  },

  watch: {
    backText(val) {
      this.updateBackText(val)
    },
  },
}

const toPng = (svg, width, height) =>
  new Promise((resolve, reject) => {
    if (!svg) {
      reject('argument "svg" is required')
    }

    if (!width || width < 1) {
      reject('argument "width" must be specified as a non-zero integer')
    }

    if (!height || height < 1) {
      reject('argument "width" must be specified as a non-zero integer')
    }

    const node = svg.cloneNode(true)
    node.setAttribute('width', width)
    node.setAttribute('height', height)

    const xml = new XMLSerializer().serializeToString(node)
    const blob = new Blob([xml], { type: 'image/svg+xml;charset=utf-8' })
    const svgData = window.URL.createObjectURL(blob)
    const image = new Image(width, height)

    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      canvas.getContext('2d').drawImage(image, 0, 0, width, height)
      window.URL.revokeObjectURL(svgData)

      const pngData = canvas.toDataURL('image/png')
      resolve(pngData)
    }

    image.src = svgData
  })

let updateBackTextOnSvg = function (val) {
  let svgContainer = document.getElementById('parent-svg-container')
  let backTextContainer = svgContainer.querySelector('#back-text-container')
  if (val.length > 44 * 5 || !backTextContainer) return
  let wrapConfig = {
    width: 44,
    indent: '',
    newline: '?!---line---break---?!',
  }
  let wrapped = wrap(val, wrapConfig).split('?!---line---break---?!')
  if (wrapped.length > 5) {
    store.commit('setLabelOptions', { key: 'backText', value: wrapped.slice(0, 5).join('\n') })
    return
  }
  backTextContainer.innerHTML = ''
  for (let idx in wrapped) {
    backTextContainer.innerHTML =
      backTextContainer.innerHTML +
      `<tspan class="back-text" id="backText" x="-5" y="${
        -7 + 8 * idx
      }" fill="#FFFFFF" font-family="'Mukta-SemiBold'" font-size="7px">${wrapped[idx]}</tspan>`
  }
}

export { BeerLabelBaseMixin, toPng, updateBackTextOnSvg }
