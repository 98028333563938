<template>
  <div class="background-color">
    <SpinnerModal :spinning="apiCallRunnin"></SpinnerModal>
    <section class="hero background-color is-fullheight-with-navbar">
      <div class="hero-body">
        <div class="container">
          <div class="pl-4 pr-4">
            <h2 class="text-heading has-text-white" v-if="labelSupportField('title')">Teksti</h2>
            <div class="field" v-if="labelSupportField('title')">
              <h2 class="has-text-white">Rivi 1</h2>
              <div class="control flex-set">
                <input v-model="title" class="input" placeholder="Ensimmäinen Teksti" type="text"
                       :maxlength="maxAllowedLength('title')"/>
                <label class="text-size-small-min has-text-white p-2">
                  <span :class="{'has-text-danger': title.length > maxAllowedLength('title')}">
                    {{ title.length }}</span>/{{maxAllowedLength('title')}}
                </label>
              </div>
              <p v-if="$v.labelData.title.$error" class="help is-danger">
                Tämä kenttä on pakollinen - max {{maxAllowedLength('title')}} merkkiä
              </p>
            </div>
            <div class="field pb-5" v-if="labelSupportField('tagline1')">
              <h3 class="text-heading-small has-text-white">Rivi 2</h3>
              <div class="control flex-set pt-2">
                <input v-model="tagline1" class="input" placeholder="Text input" type="text"
                       :maxlength="maxAllowedLength('tagline1')"/>
                <label class="text-size-small-min has-text-white p-2">
                  <span :class="{'has-text-danger': tagline1.length > maxAllowedLength('tagline1')}">
                    {{ tagline1.length }}</span>/{{maxAllowedLength('tagline1')}}
                </label>
              </div>
              <p v-if="$v.labelData.tagline1.$error" class="help is-danger">
                Merkkimäärää ei tulisi ylittää {{maxAllowedLength('tagline1')}}
              </p>
            </div>
            <div class="field pb-5" v-if="labelSupportField('tagline2')">
              <h3 class="text-heading-small has-text-white">Rivi 3</h3>
              <div class="control flex-set pt-2">
                <input v-model="tagline2" class="input" placeholder="Text input" type="text"
                       :maxlength="maxAllowedLength('tagline2')"/>
                <label class="text-size-small-min has-text-white p-2">
                  <span :class="{'has-text-danger': tagline2.length > maxAllowedLength('tagline2')}">
                    {{ tagline2.length }}</span>/{{maxAllowedLength('tagline2')}}
                </label>
              </div>
              <p v-if="$v.labelData.tagline2.$error" class="help is-danger">
                Merkkimäärää ei tulisi ylittää {{maxAllowedLength('tagline2')}}
              </p>
            </div>
            <div class="field pb-5" v-if="labelSupportField('tagline3')">
              <h3 class="text-heading-small has-text-white">Rivi 4</h3>
              <div class="control flex-set pt-2">
                <input v-model="tagline3" class="input" placeholder="Text input" type="text"
                       :maxlength="maxAllowedLength('tagline3')"/>
                <label class="text-size-small-min has-text-white p-2">
                  <span :class="{'has-text-danger': tagline3.length > maxAllowedLength('tagline3')}">
                    {{ tagline3.length }}</span>/{{maxAllowedLength('tagline3')}}
                </label>
              </div>
              <p v-if="$v.labelData.tagline3.$error" class="help is-danger">
                Merkkimäärää ei tulisi ylittää {{maxAllowedLength('tagline3')}}
              </p>
            </div>
            <div class="field pb-5" v-if="labelSupportField('tagline4')">
              <h3 class="text-heading-small has-text-white">Rivi 5</h3>
              <div class="control flex-set pt-2">
                <input v-model="tagline4" class="input" placeholder="Text input" type="text"
                       :maxlength="maxAllowedLength('tagline4')"/>
                <label class="text-size-small-min has-text-white p-2">
                  <span :class="{'has-text-danger': tagline4.length > maxAllowedLength('tagline4')}">
                    {{ tagline4.length }}</span>/{{maxAllowedLength('tagline4')}}
                </label>
              </div>
              <p v-if="$v.labelData.tagline4.$error" class="help is-danger">
                Merkkimäärää ei tulisi ylittää {{maxAllowedLength('tagline4')}}
              </p>
            </div>
            <div class="border-color-top pt-4 pb-5" v-show="labelSupportImage('mainPicture')">
              <div class="field">
                <div class="space-between pt-3">
                  <h2 class="has-text-white">Kuva</h2>
                  <button class="button dropdown-button is-primary is-outlined no-hover-effect" v-if="mainPicture"
                          @click="toggleCroppie('mainPicture')">
                    <span class="icon is-small">
                      <img alt="drop" class="icon" src="../assets/DropDownIcon.svg" svg-inline svg-sprite/>
                    </span>
                  </button>
                </div>
                <div class="control flex-set" v-show="!mainPictureShow && !mainPicture">
                  <button class="button is-primary is-medium" @click="$refs.mainPicture.click()">
                    <span class="icon">
                      <img alt="icon" class="icon" src="../assets/ImageSquare.svg" svg-inline svg-sprite/>
                    </span>
                    <span class="text-size-small has-text-black">Lataa kuva</span>
                  </button>
                  <input
                      class="is-hidden"
                      type="file"
                      id="file-upload"
                      ref="mainPicture"
                      @change="uploadFile($event, 'mainPicture')"
                      accept="image/png,image/jpeg,image/tiff"
                  />
                  <div class="pl-2 flex-column">
                    <label class="text-size-small-min has-text-white">Suurin tiedostonkoko 20Mb</label>
                    <label class="text-size-small-min has-text-white">Tuetut tiedostotyypit: jpg, tiff, png</label>
                  </div>
                </div>
              </div>
              <div class="croppie-component mt-6 transit columns" style="align-items: center" v-show="mainPictureShow">
                <div class="field croppie-action column is-1 is-offset-1">
<!--                  <button class="button">-->
<!--                    <span class="icon is-small" @click="cropFile('mainPicture', true)">-->
<!--                      <i class="fas fa-check is-medium"></i>-->
<!--                    </span>-->
<!--                  </button>-->
                  <button class="button">
                    <span class="icon is-small" @click="$refs.mainPicture.click()">
                      <i class="fas fa-image"></i>
                    </span>
                  </button>
                  <button class="button" @click="rotateImage('mainPicture')">
                    <span class="icon is-small">
                      <i class="fas fa-retweet"></i>
                    </span>
                  </button>
                  <button class="button" @click="flushPicture('mainPicture')">
                    <span class="icon is-small">
                      <i class="far fa-trash-alt"></i>
                    </span>
                  </button>
                </div>
                <div class="column is-10">
                  <vue-croppie
                    ref="mainPictureCroppieRef"
                    :enableOrientation="true"
                    :enableExif="true"
                    :viewport="mainPictureViewportWidthHeight"
                    :boundary="boundary"
                    :showZoomer="true"
                    :enableResize="false"
                    :minZoom="0.1"
                    :maxZoom="2.5"
                    @update="croppieUpdatedMain"
                  >
                  </vue-croppie>
                </div>
              </div>
            </div>
            <div class="border-color-top pt-4 pb-4" v-show="labelSupportImage('logo')">
              <div class="field">
                <div class="space-between pt-3">
                  <h2 class=" has-text-white">Logo</h2>
                  <button class="button dropdown-button is-primary is-outlined no-hover-effect" v-if="logo"
                          @click="toggleCroppie('logo')">
                    <span class="icon is-small">
                      <img alt="icon" class="icon" src="../assets/DropDownIcon.svg" svg-inline svg-sprite/>
                    </span>
                  </button>
                </div>
                <div class="control flex-set" v-show="!logoShow && !logo">
                  <button class="button is-primary is-medium" @click="$refs.logo.click()">
                    <span class="icon">
                      <img alt="icon" class="icon" src="../assets/ImageSquare.svg" svg-inline svg-sprite/>
                    </span>
                    <span class="text-size-small has-text-black">Lataa logo</span>
                  </button>
                  <div class="pl-2 flex-column">
                    <label class="text-size-small-min has-text-white">Suurin tiedostonkoko 20Mb</label>
                    <label class="text-size-small-min has-text-white">Tuetut tiedostotyypit: jpg, tiff, png</label>
                  </div>
                  <input
                      class="is-hidden"
                      type="file"
                      id="file-upload2"
                      ref="logo"
                      @change="uploadFile($event, 'logo')"
                      accept="image/png,image/jpeg,image/tiff"
                  />
                </div>
              <div class="croppie-component mt-6 transit columns" style="align-items: center" v-show="logoShow">
                <div class="field croppie-action column is-1 is-offset-1">
<!--                  <button class="button">-->
<!--                    <span class="icon is-small" @click="cropFile('logo', true)">-->
<!--                      <i class="fas fa-check is-medium"></i>-->
<!--                    </span>-->
<!--                  </button>-->
                  <button class="button">
                    <span class="icon is-small" @click="$refs.logo.click()">
                      <i class="fas fa-image"></i>
                    </span>
                  </button>
                  <button class="button" @click="rotateImage('logo')">
                    <span class="icon is-small">
                      <i class="fas fa-retweet"></i>
                    </span>
                  </button>
                  <button class="button" @click="flushPicture('logo')">
                    <span class="icon is-small">
                      <i class="far fa-trash-alt"></i>
                    </span>
                  </button>
                </div>
                <div class="column is-10">
                  <vue-croppie
                    ref="logoCroppieRef"
                    :enableOrientation="true"
                    :enableExif="true"
                    :viewport="logoViewportWidthHeight"
                    :boundary="boundary"
                    :showZoomer="true"
                    :enableResize="false"
                    :minZoom="0.5"
                    :maxZoom="2.5"
                    @update="croppieUpdatedLogo"
                  >
                  </vue-croppie>
                </div>
              </div>
              </div>
            </div>
            <div class="border-color-top pt-4 pb-4">
              <div class="field">
                <div class="pt-3">
                  <h2 class="has-text-white">Takateksti</h2>
                  <p class="text-size-small-min has-text-white">Tämä teksti tulostetaan takaetikettiin</p>
                </div>
                <textarea v-model="backText" class="textarea has-fixed-size" placeholder=""
                          :maxlength="maxAllowedLength('backText')"></textarea>
                <div class="pl-2 is-flex is-justify-content-space-between is-flex-direction-row-reverse">
                  <label class="text-size-small-min has-text-white p-2">
                    <span :class="{'has-text-danger': backText.length > maxAllowedLength('backText')}">
                      {{ backText.length }}</span>/{{maxAllowedLength('backText')}} merkkiä
                  </label>
                  <p v-show="$v.labelData.backText.$error" class="help is-danger">
                  </p>
                </div>
              </div>
            </div>
            <div class="border-color-top border-color-bottom pt-4 pb-5 mb-5">
              <div class="field">
                <h2 class="has-text-white">Tilausmäärä</h2>
                <div class="flex-row pb-4">
                  <input type="number" min="1" v-model.number=amount style="max-width: 70px"
                         class="input has-text-centered amount-input is-paddingless"/>
                  <label class="text-size-small has-text-white pl-2 pt-2">* Vähimmäistilaus 1 laatikko  sis. 24 pulloa</label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-row space-between">
            <button class="button is-rounded is-outlined is-warning has-background-white white-bg" @click="$router.push('/signin')">
              <span class="icon">
                <img alt="icon" class="icon" src="../assets/ArrowCircleLeft.svg" svg-inline svg-sprite/>
              </span>
              <span>Takaisin</span>
            </button>
            <button class="button is-warning is-normal ml-3 is-rounded wider-button">
              <span class="icon">
                <img alt="icon" class="icon" src="../assets/ShoppingCartWhite.svg" svg-inline svg-sprite/>
              </span>
              <span class="has-text-white" @click="uploadOrder()">
                Lisää ostoskoriin
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <canvas id="resizingCanvas" width="430" height="430" style="border:1px solid #d3d3d3; display: none;"></canvas>
  </div>
</template>

<script>
import VueCroppieComponent from '@/components/helpers/VueCroppieComponent'
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex'
import {maxLength, required} from 'vuelidate/lib/validators'
import {SVG_LABEL_CONFIGS} from '@/store/config'

import SpinnerModal from './SpinnerModal'

require('style-loader!../../node_modules/croppie/croppie.css')

export default {
  name: 'LabelOptionRightMenu',
  components: {
    SpinnerModal,
    'vue-croppie': VueCroppieComponent,
  },
  data() {
    return {
      apiCallRunnin: false,
      order: {
        label: null
      },
      logoShow: false,  // for croppie
      mainPictureShow: false,
      windowWidth: 0,
      croppieInitialized: false
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      (state) => state.selectedLabel,
      (newValue, oldValue) => {
        console.log(`Updating from ${oldValue} to ${newValue}`)

        if (newValue !== oldValue) {
          if (this.labelSupportImage('mainPicture') && this.mainPicture)
            this.reloadCroppie('mainPicture')
          if (this.labelSupportImage('logo') && this.logo)
            this.reloadCroppie('logo')
        }
      }
    )
  },
  beforeUnmount() {
    this.unwatch()
  },
  validations() {
    return {
      labelData: {
        title: {
          maxLength: maxLength(this.maxAllowedLength('title'))
        },
        tagline1: {
          maxLength: maxLength(this.maxAllowedLength('tagline1'))
        },
        tagline2: {
          maxLength: maxLength(this.maxAllowedLength('tagline2'))
        },
        tagline3: {
          maxLength: maxLength(this.maxAllowedLength('tagline3'))
        },
        tagline4: {
          maxLength: maxLength(this.maxAllowedLength('tagline4'))
        },
        backText: {
          maxLength: maxLength(this.maxAllowedLength('backText'))
        }
      },
      amount: {
        required
      }
    }
  },
  mounted() {
    this.setLabelOptions({key: 'orderNumber', value: '1231231'})
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    this.amount = this.labelsData[this.selectedCartItem].amount
  },
  methods: {
    ...mapActions({
       postOrder: 'postOrder',
       updateOrder: 'updateOrder',
       addLabel: 'addLabel',
       patchLabel: 'patchLabel',
       postPicture: 'postPicture'
    }),
    ...mapMutations({
      setLabelOptions: 'setLabelOptions',
      setOrderAmount: 'setOrderAmount',
      fakeTaglines: 'fakeTaglines',
      revertFakedTaglines:  'revertFakedTaglines',
      updateLabelData: 'updateLabelData',
      setPictureResolution: 'setPictureResolution'
    }),
    ...mapGetters({
      getPictureResolution: 'getPictureResolution',
      getSourcePicture: 'getSourcePicture'
    }),
    toggleCroppie(type) {
      this[`${type}Show`] = !this[`${type}Show`]
      if (!this.croppieInitialized) {
         if (this.labelSupportImage('mainPicture') && this.mainPicture)
          this.reloadCroppie('mainPicture')
        if (this.labelSupportImage('logo') && this.logo)
          this.reloadCroppie('logo')
        this.croppieInitialized = true
      }
    },
    maxAllowedLength(field) {
      return SVG_LABEL_CONFIGS[this.selectedLabel][field] ?
          SVG_LABEL_CONFIGS[this.selectedLabel][field]['maxLength'] : 10
    },
    labelSupportField(field) {
      return Object.keys(SVG_LABEL_CONFIGS[this.selectedLabel]).indexOf(field) > -1
    },
    labelSupportImage(field) {
      return SVG_LABEL_CONFIGS[this.selectedLabel]['images'].indexOf(field) > -1
    },
    async uploadOrder() {
      this.$v.$touch()

      if (this.$v.$error) return

      this.fakeTaglines()
      this.apiCallRunnin = true

      setTimeout(() => { // Making sure fakeTagLines changes are done in svg
        let label_data = this.labelsData[this.selectedCartItem]['label_data']
        // remove placeholder picture from svg
        let svgContainer = document.getElementById("parent-svg-container")
        let svgLogo = svgContainer.querySelector('#svg-logo')
        let svgMainImage = svgContainer.querySelector('#svg-main-image')
        // This will remove the inline fonts import
        svgContainer.querySelector('#svg-component').firstElementChild.remove()
        if (label_data.logo == "" && svgLogo) {
          svgLogo.outerHTML = ""
        }
        if (label_data.mainPicture == "" && svgMainImage) {
          svgMainImage.outerHTML = ""
        }
        let svg = document.getElementById('svg-component')
        let fonts = SVG_LABEL_CONFIGS[this.selectedLabel]['fontsImportString']
        let defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs')
        let style = document.createElementNS('http://www.w3.org/2000/svg', 'style')
        let node = document.createTextNode(fonts)
        style.appendChild(node)
        defs.appendChild(style)
        svg.appendChild(defs)
        let blob = new Blob([svg.outerHTML], { type: "image/svg+xml;charset=utf-8" })
        this.updateLabelData({
          'amount': this.amount,
          'order': this.getOrderUuid
        })

        let formData = new FormData()
        label_data['label_number'] = this.selectedLabel
        formData.append('label', new File([blob], 'label.svg'))
        formData.append('amount', this.amount)
        formData.append('label_data', JSON.stringify(this.labelsData[this.selectedCartItem]['label_data']))
        formData.append('order', this.getOrderUuid)

        // reset
        this.amount = 1

        if (this.getSelectedLabelUUID)
          this.patchLabel({data: formData, uuid: this.getSelectedLabelUUID}).then(() => {
            this.apiCallRunnin = false
            this.$router.push({name: 'Order', params: {uuid: this.getOrderUuid}})
          })
        else
          this.addLabel(formData).then(() => {
            this.apiCallRunnin = false
            this.$router.push({name: 'Order', params: {uuid: this.getOrderUuid}})
          })

      }, 500)
    },
    croppieUpdatedMain(val) {
      // signal handler for croppie update events
      this.cropFile('mainPicture')
    },
    croppieUpdatedLogo(val) {
      // signal handler for croppie update events
      this.cropFile('logo')
    },
    uploadFile(e, type) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      let reader = new FileReader()
      let formData  = new FormData()
      let file = files[0]
      formData.append('image', file)
      let self = this
      reader.onload = e => {
        self.postPicture(formData).then(() => {
          let image = new Image()
          //Set the Base64 string return from FileReader as source.
          image.src = self.getSourcePicture()
          image.crossOrigin = "anonymous"
          image.onload = function () {
            let res_height = this.height
            let res_width = this.width
            self.resolution = {'width': res_width, 'height': res_height}

            let canvas = document.getElementById('resizingCanvas');
            let ctx = canvas.getContext("2d");

            let width = image.width;
            let height = image.height;
            let MAX_WIDTH = 650;
            let MAX_HEIGHT = 650;

            let resolution = {'width': width, 'height': height}

            // Resize maintaining aspect ratio
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;

            resolution['scaled_width'] = width
            resolution['scaled_height'] = height

            //let pictureResolution = resolution

            // Copy the image to the canvas and resize it.
            ctx.drawImage(image, 0, 0, width, height)

            // Toss the new down-scaled image over to Croppie
            let previewPicture = canvas.toDataURL('image/png', 1)

            self[`${type}Source`] = e.target.result
            self.$refs[`${type}CroppieRef`].refresh()
            self.$refs[`${type}CroppieRef`].bind({
              url: previewPicture,
              viewport: this[`${type}ViewportWidthHeight`],
              boundary: this.boundary,
              enableResize: false,
              minZoom: 1,
              maxZoom: 2.5
            })
            .then(
                function (value) {
                  //self.setImageSource(e.target.result)
                  //self.setMinImageZoom(-9)
                  self.$root.$emit('spinning', false)
                },
                function (reason) {
                  self.$root.$emit('spinning', false)
                  console.error('Image binding failed. Reason: ', reason);
                }
            )
            self[`${type}Show`] = true
          }
        })
      }
      reader.readAsDataURL(files[0])
    },
    reloadCroppie(type) {
      this.$nextTick(() => {
        if (!this.$refs[`${type}CroppieRef`]) return
        this.$refs[`${type}CroppieRef`].refresh()
        this.$refs[`${type}CroppieRef`].bind({
          url: this[`${type}Source`],
          viewport: this[`${type}ViewportWidthHeight`],
          boundary: this.boundary,
          zoom: this[`${type}CroppieData`]['zoom'],
          // points: this[`${type}CroppieData`]['points'],
          orientation: this[`${type}CroppieData`]['orientation'],
          enableResize: false,
          minZoom: 0.1,
          maxZoom: 2.5
        })
      })
    },
    cropFile(ref, hideSection=false) {
      let options = {
        format: 'png',
        size: 'original',
        quality: 1,
        type: 'canvas'
      }
      this.$refs[`${ref}CroppieRef`].result(options, (output) => {
        this[ref] = output
      }).then(() => {
        let croppieState = this.$refs[`${ref}CroppieRef`].get()
        this[`${ref}CroppieData`] = {
          points: croppieState.points,
          zoom: croppieState.zoom,
          orientation: croppieState.orientation
        }
      })
      if (hideSection)
        this[`${ref}Show`] = false
    },
    flushPicture(type) {
      this[type] = ''
      this[`${type}Source`] = ''
      this[`${type}Show`] = false
    },
    rotateImage(ref) {
      this.$refs[`${ref}CroppieRef`].rotate(90)
    }
  },
  computed: {
    ...mapGetters({
      selectedLabel: 'getSelectedLabel',
      getSelectedLabel: 'getSelectedLabel',
      getOrderUuid: 'getOrderUuid',
      getSelectedLabelUUID: 'getSelectedLabelUUID'
    }),
    ...mapState(['labelsData', 'orderAmount', 'selectedCartItem', 'selectedLabel']),
    logoViewportWidthHeight() {
      if (this.labelSupportField(`logoViewport`))
        return SVG_LABEL_CONFIGS[this.selectedLabel][`logoViewport`]
      return {width: 75, height: 75}
    },
    mainPictureViewportWidthHeight() {
      if (this.labelSupportField(`mainPictureViewport`))
        return SVG_LABEL_CONFIGS[this.selectedLabel][`mainPictureViewport`]
      return {width: 150, height: 150}
    },
    amount: {
      get() {
        return this.orderAmount
      },
      set(val) {
        this.setOrderAmount(val)
      }
    },
    title: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['title']
      },
      set(v) {
        this.setLabelOptions({key: 'title', value: v})
      }
    },
    tagline1: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['tagline1']
      },
      set(v) {
        this.setLabelOptions({key: 'tagline1', value: v})
      }
    },
    tagline2: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['tagline2']
      },
      set(v) {
        this.setLabelOptions({key: 'tagline2', value: v})
      }
    },
    tagline3: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['tagline3']
      },
      set(v) {
        this.setLabelOptions({key: 'tagline3', value: v})
      }
    },
    tagline4: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['tagline4']
      },
      set(v) {
        this.setLabelOptions({key: 'tagline4', value: v})
      }
    },
    backText: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['backText']
      },
      set(v) {
        this.setLabelOptions({key: 'backText', value: v})
      }
    },
    logo: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['logo']
      },
      set(v) {
        this.setLabelOptions({key: 'logo', value: v})
      }
    },
    logoSource: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['logoSource']
      },
      set(v) {
        this.setLabelOptions({key: 'logoSource', value: v})
      }
    },
    logoCroppieData: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['logoCroppieData']
      },
      set(v) {
        this.setLabelOptions({key: 'logoCroppieData', value: v})
      }
    },
    mainPicture: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['mainPicture']
      },
      set(v) {
        this.setLabelOptions({key: 'mainPicture', value: v})
      }
    },
    mainPictureSource: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['mainPictureSource']
      },
      set(v) {
        this.setLabelOptions({key: 'mainPictureSource', value: v})
      }
    },
    mainPictureCroppieData: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['mainPictureCroppieData']
      },
      set(v) {
        this.setLabelOptions({key: 'mainPictureCroppieData', value: v})
      }
    },
    mainPictureDimensions: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['mainPictureDimensions']
      },
      set(v) {
        this.setLabelOptions({key: 'mainPictureDimensions', value: v})
      }
    },
    logoDimensions: {
      get() {
        return this.labelsData[this.selectedCartItem]['label_data']['logoDimensions']
      },
      set(v) {
        this.setLabelOptions({key: 'logoDimensions', value: v})
      }
    },
    boundary() {
      if (this.windowWidth < 450) {
        return {width: 180, height: 180}
      }
      return {width: 400, height: 400}
    },
  }
}
</script>

<style lang="scss" scoped>

.hero-body .container {
  background-color: #282C43;
}

.border-color-top {
  border-top: 1px solid #89E2F9;
}

.border-color-bottom {
  border-bottom: 1px solid #89E2F9;
}

.croppie-container {
  width: auto;
  margin-left: 20px;
}

.croppie-action {
  text-align: center;
  button {
    background: none;
    color: #ffff;
    border: none;
    width: 50px;
  }
}

.dropdown-button {
  width: 40px !important;
  height: 40px !important;
}

.hero-body {
  @media (max-width: 400px) {
    padding: 0px;
    .container {
      padding-left: 10px;
    }
  }
}

</style>
