import camelCase from 'lodash/camelCase'
/*
 * createAsyncMutation('GET_INFO_ASYNC') will lead to:
 *
 * GET_INFO_ASYNC = {
 *      BASE: GET_INFO_ASYNC_BASE,
 *      SUCCESS: GET_INFO_ASYNC_SUCCESS,
 *      PENDING: GET_INFO_ASYNC_PENDING,
 *      FAILURE: GET_INFO_ASYNC_FAILURE,
 *      loadingKey: getInfoAsyncPending,
 *      errorCode: getInfoAsyncErrorCode,
 *      stateKey: getInfoAsyncData
 * }
 *
 */
const createAsyncMutation = (type) => ({
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  PENDING: `${type}_PENDING`,
  loadingKey: camelCase(`${type}_PENDING`),
  statusCode: `${camelCase(type)}StatusCode`,
  stateKey: camelCase(`${type}_DATA`),
  errorKey: camelCase(`${type}_ERRORS`)
})

export const STRIPE_SESSION = createAsyncMutation('POST_USER')
export const POST_CUSTOMER = createAsyncMutation('POST_CUSTOMER')
export const POST_ORDER = createAsyncMutation('POST_ORDER')
export const POST_CONTACT = createAsyncMutation('POST_CONTACT')
export const POST_CONTACT_EMAIL = createAsyncMutation('POST_CONTACT_EMAIL')
export const UPDATE_CONTACT = createAsyncMutation('UPDATE_CONTACT')
export const UPDATE_ORDER = createAsyncMutation('UPDATE_ORDER')
export const GET_ORDER = createAsyncMutation('GET_ORDER')
export const GET_SHIPPING_PRICE = createAsyncMutation('GET_SHIPPING_PRICE')
export const GET_PRICING = createAsyncMutation('GET_PRICING')
export const USE_VOUCHER_DISCOUNT = createAsyncMutation('USE_VOUCHER_DISCOUNT')
export const POST_LABEL = createAsyncMutation('POST_LABEL')
export const PATCH_LABEL = createAsyncMutation('PATCH_LABEL')
export const DELETE_LABEL = createAsyncMutation('DELETE_LABEL')
export const POST_PICTURE = createAsyncMutation('POST_PICTURE')
