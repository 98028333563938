<template>
  <div class="bg-color">
    <div class="toptriangle"></div>
    <nav class="navbar is-transparent bg-color">
      <div class="navbar-menu">
        <div class="navbar-end nav-setting">
          <div class="navbar-item" :class="{'is-active': this.active === 'label'}">
            <a v-on:click="editorNavigation">
              <span class="icon is-large is-hidden-fullhd is-hidden-widescreen-only">
                <img alt="icon" class="icon" src="../assets/PencilCircle.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-medium is-hidden-widescreen-only is-hidden-desktop-only">
                <img alt="icon" class="icon" src="../assets/PencilCircle.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-very-large is-hidden-fullhd is-hidden-desktop-only">
                <img alt="icon" class="icon" src="../assets/PencilCircle.svg" svg-inline svg-sprite/>
              </span>
            </a>
          </div>
          <div class="navbar-item" :class="{'is-active': this.active === 'order'}">
            <a v-on:click="orderNavigation()"><div class="counter is-pulled-right has-text-white">{{labelsData.length}}</div>
              <span class="icon is-large is-hidden-fullhd is-hidden-widescreen-only">
                <img alt="icon" class="icon" src="../assets/ShoppingCartWhite.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-medium is-hidden-widescreen-only is-hidden-desktop-only">
                <img alt="icon" class="icon" src="../assets/ShoppingCartWhite.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-very-large is-hidden-fullhd is-hidden-desktop-only">
                <img alt="icon" class="icon" src="../assets/ShoppingCartWhite.svg" svg-inline svg-sprite/>
              </span>
            </a>
          </div>
          <div class="navbar-item" :class="{'is-active': this.active === 'summary'}">
            <a v-on:click="summaryNavigation()">
              <span class="icon is-large is-hidden-fullhd is-hidden-widescreen-only">
                <img alt="icon" class="icon" src="../assets/Receipt.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-medium is-hidden-widescreen-only is-hidden-desktop-only">
                <img alt="icon" class="icon" src="../assets/Receipt.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-very-large is-hidden-fullhd is-hidden-desktop-only">
                <img alt="icon" class="icon" src="../assets/Receipt.svg" svg-inline svg-sprite/>
              </span>
            </a>
          </div>
          <div class="navbar-item">
            <a href="#">
              <span class="icon is-large is-hidden-fullhd is-hidden-widescreen-only">
                <img alt="icon" class="icon" src="../assets/Package.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-medium is-hidden-widescreen-only is-hidden-desktop-only">
                <img alt="icon" class="icon" src="../assets/Package.svg" svg-inline svg-sprite/>
              </span>
              <span class="icon is-very-large is-hidden-fullhd is-hidden-desktop-only">
                <img alt="icon" class="icon" src="../assets/Package.svg" svg-inline svg-sprite/>
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex"
export default {
  name: 'LabelOptionNavbar',
  props: {
    active: {
      default: 'label'
    }
  },
  computed: {
    ...mapState(['labelsData', 'postContactData']),
    ...mapGetters(['getOrderUuid']),
  },
  methods: {
    editorNavigation() {
      this.$router.push({ name: 'Label', params: {uuid: this.getOrderUuid} })
    },
    orderNavigation() {
      if (this.labelsData[0].uuid) {
        this.$router.push({ name: 'Order', params: {uuid: this.getOrderUuid} })
      }
    },
    summaryNavigation() {
      if (this.postContactData) {
        this.$router.push({ name: 'Summary' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.nav-setting {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.bg-color {
  background-color: #202335;
}

.toptriangle {
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 98px solid #00627a;
  border-bottom: 30px solid transparent;
}

.roundnav {
  border-radius: 25px;
  padding-top: 15px;
}

.navbar-item {
  color: #00ffff;
  margin: 5px;
  background-color: #282c43;
  border-radius: 50%;
}

.navbar-item.is-active {
  background-color: #282c43;
  border-radius: 50px 1px 1px 50px;
  width: 100%;
  color: #ffffff;
  border-bottom-color: #ff5858;
}

.navbar-item a {
  color: inherit;
  text-decoration: none;
  cursor: default;
}

.icon.is-medium {
  height: 1.8rem !important;
  width: 1.8rem !important;
}

.icon.is-very-large {
  height: 4rem;
  width: 4rem;
}

.navbar {
  position: initial;
}

.counter {
  background: #e9721f;
  position: absolute;
  left: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  border: 1px solid white;
  margin: -6px 0px 0px -2px;
  padding: 5px 2px 2px 2px;
  font-weight: bold;
}

.icon {
    cursor: pointer;
}

</style>
