<template>
  <div>
    <nav v-if="$route.name != 'Home'" class="navbar" role="navigation">
      <div class="navbar-brand is-hidden-touch">
        <span class="logo-color image-setting is-invisible" @click="$router.push('/')">
          <img alt="Placeholder image" src="../assets/Tuunerilogonav.svg"/>
        </span>
      </div>
      <div class="navbar-brand is-active is-pulled-left is-hidden-desktop">
        <span class="logo-color image-setting is-invisible" @click="$router.push('/')">
          <img alt="Placeholder image" src="../assets/Tuunerilogonav.svg"/>
        </span>
      </div>
      <div class="navbar-menu is-active">
        <div class="navbar-end">
          <a class="navbar-item is-hidden-touch" @click="$refs.terms.closeModal(); $refs.terms.showModal()">
            Yleiset Toimitusehdot
          </a>
          <terms-modal ref="terms"></terms-modal>
          <a class="navbar-item  pl-5 pr-5 is-hidden-touch" @click="$refs.register.closeModal(); $refs.register.showModal()">
            Asiakasrekisterin Tietosuoja
          </a>
          <register-modal ref="register"></register-modal>
          <a class="navbar-item is-hidden-desktop is-pulled-right">
            <div class="mobile-menu-icon" @click="$refs.mobilemodal.closeModal(); $refs.mobilemodal.showModal()">
              <i class="fas fa-bars"></i>
            </div>
            <mobile-menu-modal ref="mobilemodal"></mobile-menu-modal>
          </a>
        </div>
      </div>
    </nav>
    <div v-else class="home-header">
      <div class="container has-background-white">
        <div class="columns pt-5">
          <div class="column is-8 is-offset-2">
            <img alt="Placeholder image" src="../assets/img/header_logo@2x.png"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import termsModal from "@/components/termsModal";
import registerModal from "@/components/registerModal";
import mobileMenuModal from "@/components/mobileMenuModal";

export default {
  name: 'Header',
  components: {
    termsModal,
    registerModal,
    mobileMenuModal
  },
  data() {
    return {}
  }
}
</script>
<style scoped lang="scss">

.logo-color {
  cursor: pointer;
  background: #494949;
}

.image-setting {
  width: 150px;
  height: fit-content;
  padding: 10px 30px 10px 10px;
}

a.navbar-item {
  font-family: 'Avenir';
  font-weight: 800;
  font-size: 16px;
  color: black;
}

.navbar {
  @media (max-width: 1024px) {
    height: 64px;
    display: block;
    .navbar-menu {
      height: 64px;
    }
  }
}

.mobile-menu-icon {
  svg {
    color: black;
  }
}
.home-header {
  background-color: #eeeeee;
  .container {
    max-width: 1000px !important;
    height: 14vh;
    @media (max-width: 400px) {
      height: 8vh;
    }
  }
}

.navbar-menu {
  background-color: white;
}
</style>
