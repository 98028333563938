import Vue from 'vue'
import router from './router'
import {sync} from 'vuex-router-sync'
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store'
import Vuelidate from 'vuelidate'
import VueGtag from "vue-gtag";
import 'croppie/croppie.css'

require('./assets/css/mystyles.css')
require('./assets/sass/mybulma.scss')

// if(['production', 'staging'].includes(process.env.NODE_ENV)){
//   Sentry.init({
//     dsn: "https://52d162fb1abd4b1b8890a7d13576c181@o185598.ingest.sentry.io/5538923",
//     integrations: [new VueIntegration({ Vue: app, attachProps: true })],
//     environment: process.env.NODE_ENV,
//     debug: true,
//   })
// }

/*
new Vue({
  render: (h) => h(App),
}).$mount("#app");
*/

const VueCookie = require('vue-cookie');
Vue.use(VueCookie);
Vue.use(Vuelidate)
Vue.use(VueAxios, axios);

// restore auth token after reload before router kicks in
{
  let token = VueCookie.get('token')
  //store.commit('STORE_AUTH_TOKEN', token);
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
  }
}

sync(store, router)

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
}, router);

const app = new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");

export {app, router, store}

