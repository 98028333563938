<template>
  <div class="height">
    <div class="hero">
      <div class="hero-body is-paddingless">
        <div class="container is-fullhd">
          <div class="columns is-marginless" v-if="!isMobile">
            <div class="column is-6 is-paddingless">
              <LabelOptionLeftComponent/>
            </div>
            <div class="column is-6 pl-0 pr-0 bottle-fixed" ref="svgContainer">
              <RightBottleSection/>
            </div>
          </div>
          <div class="columns is-multiline is-marginless is-hidden-desktop" v-else ref="svgContainer">
            <div class="column is-12 pl-0 pr-0 pt-0">
              <RightBottleSection/>
            </div>
            <div class="column is-12 is-paddingless">
              <LabelOptionLeftComponent/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabelOptionLeftComponent from '../components/LabelOptionLeftComponent'
import RightBottleSection from '../components/RightBottleSection'
import ScreenSizeMixin from '@/mixins/ScreenSizeMixin'

export default {
  name: 'LabelOption',
  components: {
    RightBottleSection,
    LabelOptionLeftComponent,
  },
  mixins: [ScreenSizeMixin],
  data() {
    return {}
  },
  beforeRouteLeave (to, from, next) {
    if (to.name != 'Order') {
      const answer = window.confirm('Haluatko varmasti poistua palvelusta?')
      if (!answer) return false
    }
    next()
  }
}
</script>

<style lang="scss" scoped>

.nav-height {
  height: 80px;
}

.height {
  height: fit-content;
}
</style>
