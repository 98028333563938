<template>
  <div class="section-right">
    <div class="columns is-multiline is-marginless is-hidden-touch bottle-header-desktop">
      <div class="nav-height column is-10 is-paddingless">
        <LabelTopNav />
      </div>
      <div
        class="field pt-5 column is-6 is-5-mobile is-offset-0-mobile is-offset-1 is-flex is-justify-content-center is-relative"
        v-if="!isThreeDeeView"
      >
        <input v-model="frontSide" id="switch-color-info" class="switch is-primary" type="checkbox" />
        <label for="switch-color-info">{{ switchButtonText }}</label>
      </div>
    </div>

    <div class="columns is-multiline is-marginless is-hidden-desktop is-mobile">
      <div class="nav-height column is-12 is-paddingless">
        <LabelTopNav />
      </div>
      <div
        class="field column is-5 is-5-mobile is-offset-0-mobile is-offset-3 is-flex is-justify-content-center is-relative"
        v-if="!isThreeDeeView"
      >
        <input v-model="frontSide" id="switch-color-info-mobile" class="switch is-primary" type="checkbox" />
        <label for="switch-color-info-mobile">{{ switchButtonText }}</label>
      </div>
    </div>

    <div class="field pb-5 column is-5 is-9-mobile ml-1-mobile is-flex is-justify-content-center is-relative">
      <input v-model="isThreeDeeView" id="three-dee-view-toggle" class="switch is-primary" type="checkbox" />
      <label for="three-dee-view-toggle">Switch to {{ isThreeDeeView ? '2D' : '3D' }} view</label>
    </div>

    <template v-if="isThreeDeeView && refsLoaded">
      <div class="columns is-5 is-marginless pt-5 pl-2">
        <ThreeDeePreview />
      </div>
    </template>

    <template>
      <div class="columns is-marginless pt-5" :class="[isThreeDeeView ? 'hidden' : '']">
        <div class="bottle-column column is-12 is-paddingless">
          <div class="parent-svg-container" id="parent-svg-container" v-if="refsLoaded">
            <button class="button is-small is-primary button-round" @click="showFullSizeLabel = true">
              <span class="icon">
                <i class="fas fa-search-plus"></i>
              </span>
            </button>
            <button
              class="button is-small is-primary button-round-info"
              @click="
                $refs.info.closeModal()
                $refs.info.showModal()
              "
            >
              <span class="icon">
                <i class="fas fa-info-circle"></i>
              </span>
            </button>
            <info-modal ref="info"></info-modal>
            <img alt="Placeholder image" :style="imageStyle" src="@/assets/labels/bottle.svg" ref="bottleImage" />
            <div class="svg-container transit" :style="svgStyle">
              <template v-if="refsLoaded">
                <div class="left-shadow"></div>
                <div class="right-shadow" :style="rightShadowStyle"></div>
                <keep-alive>
                  <component
                    :is="currentLabel"
                    id="svg-component"
                    ref="label"
                    :title="labelsData[selectedCartItem]['label_data']['title']"
                    :tagline1="labelsData[selectedCartItem]['label_data']['tagline1']"
                    :tagline2="labelsData[selectedCartItem]['label_data']['tagline2']"
                    :tagline3="labelsData[selectedCartItem]['label_data']['tagline3']"
                    :tagline4="labelsData[selectedCartItem]['label_data']['tagline4']"
                    :logo="labelsData[selectedCartItem]['label_data']['logo']"
                    :backText="labelsData[selectedCartItem]['label_data']['backText']"
                    :mainPicture="labelsData[selectedCartItem]['label_data']['mainPicture']"
                    :orderNumber="labelsData[selectedCartItem]['label_data']['orderNumber']"
                  ></component>
                </keep-alive>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Modal -->

    <div class="modal" :class="{ 'is-active': showFullSizeLabel }" v-if="showFullSizeLabel">
      <div class="modal-background" @click="showFullSizeLabel = false"></div>
      <div class="modal-card">
        <button class="delete" aria-label="close" @click="showFullSizeLabel = false"></button>
        <svg
          v-html="svgLogoHtml()"
          viewBox="0 0 399.69 209.76"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
      </div>
    </div>
  </div>
</template>

<script>
import getSize from 'element-size'
import { mapState, mapGetters } from 'vuex'

import LabelTopNav from '@/components/LabelTopNav'
import infoModal from '@/components/infoModal'
import ThreeDeePreview from '@/components/3dPreview/ThreeDeePreview'
import { updateBackTextOnSvg } from '@/components/labels/utils'

export default {
  name: 'RightBottleSection',

  components: {
    LabelTopNav,
    infoModal,
    ThreeDeePreview,
  },

  data() {
    return {
      refsLoaded: false,
      bottleLoaded: false,
      frontSide: true,
      showFullSizeLabel: false,
      height: 0,
      isThreeDeeView: false,
    }
  },

  created() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape' && this.showFullSizeLabel) {
        this.showFullSizeLabel = false
      }
    }
    document.addEventListener('keydown', escapeHandler)
    window.addEventListener('resize', this.setMaxHeight)
    this.unwatch = this.$store.watch(
      (state) => state.selectedCartItem,
      (newValue, oldValue) => {
        console.log(`Updating from ${oldValue} to ${newValue}`)
        this.$nextTick(() => {
          updateBackTextOnSvg(this.labelsData[this.selectedCartItem]['label_data']['backText'])
        })
      }
    )
  },

  beforeUnmount() {
    this.unwatch()
    window.removeEventListener('resize', this.setMaxHeight)
  },

  mounted() {
    this.refsLoaded = true
    this.setMaxHeight()
  },

  computed: {
    ...mapState(['labelsData', 'selectedCartItem']),
    ...mapGetters(['getSelectedLabel']),

    currentLabel() {
      if (!this.getSelectedLabel) {
        return () => ({})
      }
      return () => import(`@/components/labels/${this.getSelectedLabel}`)
    },

    imageHeight() {
      if (this.$parent.$refs.svgContainer) {
        this.bottleLoaded = true
        return `${this.height * 0.8}px`
      }
      return '100%'
    },

    imageStyle() {
      return { height: this.imageHeight, width: 'auto' }
    },

    svgStyle() {
      let style = {
        height: 'auto',
        width: '0px',
      }
      if (this.bottleLoaded && this.$parent.$refs.svgContainer) style['width'] = `${this.height * 0.538}px`
      if (!this.frontSide) style['right'] = '4.5%'
      else style['left'] = 0
      return style
    },

    rightShadowStyle() {
      let style = {
        left: '200px',
      }
      if (this.bottleLoaded && this.$parent.$refs.svgContainer) {
        let width = this.height * 0.171
        style['left'] = `${width}px`
      }
      return style
    },

    switchButtonText() {
      if (this.frontSide) return 'Etuetiketti'
      return 'Takaetiketti'
    },
  },

  methods: {
    svgLogoHtml() {
      return this.$refs.label.$el.innerHTML
    },

    setMaxHeight() {
      if (screen.width < 1023) {
        this.height = 500
        return
      }
      if (this.$parent.$refs && this.$parent.$refs.svgContainer)
        this.height = getSize(this.$parent.$refs.svgContainer)[1]
      if (this.height === 0) {
        this.height = 600
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.section-right {
  .hidden {
    display: none;
  }

  .bottle-column {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-height {
    height: 70px;
  }

  .parent-svg-container {
    position: relative;
    overflow: hidden;

    .svg-container {
      position: absolute;
      top: 48%;

      .left-shadow,
      .right-shadow {
        position: absolute;
        height: 98%;
        width: 5%;
        background: red;
      }

      .left-shadow {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(7, 7, 7, 0) 100%);
      }

      .right-shadow {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.3) 0%, rgba(7, 7, 7, 0) 100%);
      }
    }
  }

  .button-round {
    position: absolute;
    right: 0%;
    top: 20%;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }

  .button-round-info {
    position: absolute;
    right: 0%;
    top: 25%;
    border-radius: 50%;

    height: 30px;
    width: 30px;
  }

  .modal-card {
    background: #7c391bd6;
    padding: 10px;
    border-radius: 4px;
  }
}

.bottle-header-desktop {
  position: absolute;
  top: 17%;
  left: 10%;
  z-index: 1;
}
</style>
