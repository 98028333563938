<template>
  <div class="modal" :class="{'is-active': active}">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <div class="has-text-centered py-2 pl-6 ml-4 is-invisible">
          <span>
            <img alt="background" src="../assets/Tuunerilogonav.svg" svg-inline svg-sprite/>
          </span>
        </div>
        <!-- <button class="delete" aria-label="close"></button> -->
      </header>
      <section class="modal-card-body has-text-centered">
          <!-- Content ... -->
          <div class="content pb-6">
            <h2>Asiakasrekisterin tietosuoja</h2>
            <h2>TUUNERI ASIAKASREKISTERIN TIETOSUOJASELOSTE</h2>

            <h2>1. Rekisterinpitäjä POLARMONKS OY</h2>

            <p>Rekisterin ylläpitäjä on POLARMONKS OY (3151822-4)</p>

            <p>Rekisteriasioiden yhteyshenkilö on: Samuli Kansa, Polarmonks Oy</p>

            <p>Puhelin: 0443440382</p>

            <p>Sähköposti. samuli@polarmonks.fi</p>

            <p>
              <span class="has-text-weight-bold">Rekisterin nimi:</span>
              Polarmonks Oy:n sekä Tuuneri verkkokaupan asiakasrekisteri.
            </p>

            <p>
              Kuvaamme tässä tietosuojaselosteessa, miten Tuuneri palveluun kuuluvien yritysten liiketoimintojen
              yhteydessä käsitellään kuluttajien sekä yritysten, muiden yhteisöjen ja sidosryhmien yhteyshenkilöiden
              henkilötietoja.
            </p>

            <ul>
              <li>
                Polarmonks Oy ja Tuuneriin Oyj on sitoutunut noudattamaan Suomen henkilötietolakia, tietoyhteiskuntakaarta
                sekä muuta soveltuvaa tietosuojalainsäädäntöä, joka liittyy rekisteröidyn yksityisyyteen; sekä
                käsittelemään henkilötietoja hyvän tiedonhallinta- ja tietojenkäsittelytavan mukaisesti.
              </li>
              <li>
                ”Henkilötiedolla” tarkoitetaan tietoa, josta yksittäinen henkilö voi olla tunnistettavissa.
                Henkilötietojen lisäksi keräämme myös ei-henkilökohtaisia tietoja, joilla tarkoitetaan Palveluiden
                käyttöä koskevia tietoja, joista ei voi tunnistaa yksittäistä henkilöä.
              </li>
              <li>
                <p>Voimme muuttaa tietosuojakäytäntöämme alla tarkemmin kuvatusti:</p>
                <p>o Palveluiden käytön jatkaminen voi kuitenkin edellyttää muutoksien hyväksymistä</p>
              </li>
              <p>
                Saadaksesi tarkempia tietoja Tuuneriin Oyj:n sekä Polarmonks Oy:n tietosuojakäytännöistä, ole hyvä ja perehdy
                tähän tietosuojakäytäntöön kokonaisuudessaan. Tarvittaessa voit milloin tahansa ottaa yhteyttä Polarmonks
                Oy:hen ylempänä ilmoitettuja yhteystietoja käyttäen.
              </p>
            </ul>

            <h2>2. Henkilötietojen käsittelyn tarkoitus</h2>
            <ul>
              <li>
                Henkilötietoja käytetään palveluiden ja tuotteiden tilaus-, toimitus- ja laskutusprosessien hoitamiseen.
                Yhteyshenkilöiden tietoja tarvitaan normaalin yhteydenpidon onnistumiseksi, sekä sähköisten tilauskanavien
                käyttöön.
              </li>
              <li>
                Lisäksi henkilötietoja käsitellään asiakkaille suunnatussa viestinnässä kuten tiedotus- ja
                uutisointitarkoituksissa sekä markkinoinnissa, jonka osana henkilötietoja käsitellään myös
                suoramarkkinointiin ja sähköiseen suoramarkkinointiin liittyvissä tarkoituksissa. Asiakkaalla on oikeus
                kieltää hänelle kohdistettu suoramarkkinointi.
              </li>
            </ul>

            <h2>3. Rekisterin tietosisältö</h2>

            <ul>
              <li>
                Keräämme tietoja asiakkaan Tuuneriin Oyj:n yhteyshenkilöiden yhteystiedoista sekä näiden tilaus-, toimitus-
                ja laskutusprosessissa tarvittavista muista tiedoista. Näitä tietoja ovat Nimi, Työnantaja, Osoitetiedot,
                Puhelinnumero, Sähköposti, Käyttäjätunnus, Asiakaspalautteet, Vastaukset, Yhteyshenkilön toimittama
                aineisto, Kommentit. Keräämme myös teknistä tietoa palveluiden käytöstä. Tällaista tietoa ovat
                Käyttäjätunnus, IP-osoite, Palveluun kirjautumistiedot ja kirjautuneena tehdyt toimenpiteet Palvelussa.
                Ensisijaisena tietolähteenä ovat Asiakas ja Asiakkaan Yhteyshenkilöt.
              </li>
            </ul>

            <h2>4. Tietojen luovuttaminen</h2>
            <ul>
              <li>
                Henkilötietoja voidaan luovuttaa vain perusteltuja käyttötarkoituksia varten tilaus-, toimitus- ja
                laskutusprosessin toimivuuden varmistamiseksi. Luovuttamisessa noudatetaan kulloinkin voimassa olevan
                henkilötietolainsäädännön vaatimuksia. Esimerkiksi Polarmonks Oy sekä Tuuneriin Oyj voivat luovuttaa
                Yhteyshenkilöiden yhteystietoja alihankkijoidensa (esim. kuljetusliikkeet) käyttöön tilaus-, toimitus-
                ja laskutusprosessin toimivuuden varmistamiseksi.
              </li>
              <li>
                Voimme luovuttaa rekisteröidyn tietoja myös toimivaltaisten viranomaisten tai muiden tahojen esittämien
                vaatimusten edellyttämällä, kulloinkin voimassaolevaan lainsäädäntöön perustuvalla tavalla, taikka
                Palveluiden Käyttöehtojen noudattamisen valvomiseksi ja varmistamiseksi sekä Palveluiden turvallisuuden
                takaamiseksi.
              </li>
              <li>
                Tietoja voidaan luovuttaa mahdollisen yrityskaupan, fuusion, ulkoistuksen tai liiketoimintakaupan
                yhteydessä sekä konsernisuhteeseen tai muuhun taloudelliseen yhteenliittymään liittyen näiden
                osapuolille.
              </li>
              <li>
                Tietoja ei luovuteta kolmansille osapuolille markkinointi- tai suoramarkkinointitarkoituksiin ilman
                rekisteröidyn suostumusta. Voimme luovuttaa rekisteröidyn tietoja kuitenkin muille kolmansille
                osapuolille, mikäli rekisteröity on antanut siihen suostumuksensa esimerkiksi kytkemällä Palvelut
                kolmansien osapuolten palveluihin, kuten sosiaalisen median palveluihin tai muutoin.
              </li>
            </ul>

            <h2>5. Tietojen siirto EU:n tai ETA:n ulkopuolelle</h2>
            <ul>
              <li>
                Emme pääsääntöisesti siirrä henkilötietoja EU:n tai ETA:n ulkopuolelle. Jos tietoja siirretään EU:n tai
                ETA:n ulkopuolelle, huolehdimme henkilötietojen suojan riittävästä tasosta muun muassa sopimalla
                henkilötietojen luottamuksellisuuteen ja käsittelyyn liittyvistä asioista lainsäädännön edellyttämällä
                tavalla, esimerkiksi Euroopan Komission hyväksymiä mallisopimuslausekkeita käyttäen, ja muuten siten,
                että henkilötietojen käsittely tapahtuu tämän tietosuojakäytännön mukaisesti.
              </li>
            </ul>

            <h2>6. Rekisterin suojaus</h2>
            <ul>
              <li>
                Rekistereitämme suojataan asianmukaisesti teknisin ja organisatorisin toimenpitein. Varmistamme myös
                järjestelmiemme vikasietoisuuden ja tietojen palauttamismahdollisuudet.
              </li>
              <li>
                Rekistereitä ylläpidetään asianmukaisesti suojatuilla palvelimella ja palveluissa. Näihin suojauksiin
                kuuluu muun muassa pääsynhallinta, kulunvalvonta, palomuurit ja salasanasuojaus, joka mahdollistaa
                tallennettuun tietoon pääsyn vain Polarmonks Oy:n ja Tuuneriin Oyj:n työntekijöille sekä yhtiöiden kanssa
                sopimussuhteessa oleville alihankkijoille.
              </li>
              <li>
                Ilmoittamme Palveluiden kautta mahdollisista tietoturvauhkista ja mahdollisista tietoturvaloukkauksista
                suoraan viranomaisille tai rekisteröidylle soveltuvan lainsäädännön mukaisesti. Voimme myös tilapäisesti
                sulkea Palvelut henkilötietojen suojaamiseksi.
              </li>
              <li>
                Henkilörekisterin manuaalisiin aineistoihin on rajoitettu pääsy vain niillä Polarmonks Oy:n ja Tuuneriin
                Oyj:n ja sen alihankkijoiden henkilöillä, joilla on asianmukainen tarve ko. tietoon.
              </li>
            </ul>

            <h2>7. Rekisteröidyn oikeudet</h2>
            <ul>
              <li>
                Henkilötietojen käsittelyssä noudatetaan kulloinkin voimassa olevan henkilötietolainsäädännön vaatimuksia.
              </li>
              <li>
                Rekisteröidyllä on oikeus tarkastaa Tuuneriin Oyj:n henkilörekistereissä olevat itseään koskevat tiedot.
              </li>
              <li>
                Rekisteröidyllä on oikeus pyytää häntä koskeva virheellinen henkilötieto oikaistavaksi, täydennettäväksi
                tai poistettavaksi rekisteristä, mikäli tieto on henkilötietojen käsittelyn tarkoituksen kannalta
                virheellinen, tarpeeton, puutteellinen tai vanhentunut.
              </li>
            </ul>

            <h2>8. Henkilörekisterin ja rekisteritietojen säilytys, arkistointi ja hävittäminen.</h2>
            <ul>
              <li>Noudatamme arkistoinnin ja hävittämisen osalta voimassa olevia henkilötietolainsäädännön vaatimuksia.</li>
              <li>Yhteystiedot poistetaan rekisteristä kun asiakas sitä pyytää.</li>
              <li>Ilmoitamme rekisteriin kohdistuvista mahdollisista tietoturvauhkista ja mahdollisista
                tietoturvaloukkauksista suoraan viranomaisille tai rekisteröidylle soveltuvan lainsäädännön mukaisesti.
              </li>
            </ul>

          </div>
      </section>
      <footer class="modal-card-foot is-justify-content-center">
        <button class="button is-primary is-rounded blue-button" @click="closeModal()">
          <span class="has-text-black">OK</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'registerModal',
  data() {
    return {
      active: false
    }
  },
  methods: {
    closeModal(){
      this.active = false;
    },
    showModal(){
      this.active = true;
    }
  }
}
</script>
<style type="scss" scoped>

.modal-card {
  width: 60%;
  border-radius: 30px;
}

.content {
  text-align: left;
}

.modal-card-head {
  height: auto;
  border-radius: 30px 30px 0 0;
}

h2 {
  color: black;
}

p {
  color: black;
}

li {
  color: black;
  font-family: 'Avenir';
}
</style>
