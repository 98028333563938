<template>
  <div>
    <div class="is-hidden-touch nav-setting">
      <template v-if="isLabelPage">
        <span class="icon" @click="switchLabel($event, -1)">
          <i class="fas fa-caret-left icon is-large"></i>
        </span>
        <label class="text-size-small has-text-black pl-4 pr-4">Vaihda teema</label>
        <span @click="switchLabel($event, 1)">
          <i class="fas fa-caret-right icon is-large"></i>
        </span>
      </template>
    </div>
    <div class="bg-color is-hidden-desktop nav-setting">
      <template v-if="isLabelPage">
        <span class="icon" @click="switchLabel($event, -1)">
          <i class="fas fa-caret-left has-text-white icon is-large"></i>
        </span>
        <label class="text-size-small has-text-black pl-4 pr-4">Vaihda teema</label>
        <span @click="switchLabel($event, 1)">
          <i class="fas fa-caret-right has-text-white icon is-large"></i>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: 'LabelTopNav',
  data() {
    return {
        blockButtons: false
    }
  },
  methods: {
    ...mapMutations(['changeLabel']),
    switchLabel(event, direction) {
        if (!this.blockButtons) {
          this.changeLabel(direction)
          this.blockButtons = true
          let self = this
          setTimeout(() => {
            self.blockButtons = false
          },200)
        }
    }
  },
  computed: {
    isLabelPage() {
      return this.$router.currentRoute.name === 'Label'
    }
  }
}
</script>

<style lang="scss" scoped>

.nav-setting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    cursor: pointer;
    svg {
      color: #dcf6fd;
    }
  }
}

.bg-color {
  background-color: #dcf6fd;
  height: 70px;
}

</style>
