<template>
  <div class="hero is-fullheight" style="overflow: hidden; background-color: #eeeeee;">
    <Header/>
    <div id="nav"></div>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters([
      'authToken',
    ])
  },
    watch: {
      authToken: function () {
        if (this.authToken) {
          this.axios.defaults.headers.common['Authorization'] = 'Token ' + this.authToken;
        }
      }
    }
}
</script>
<style lang="scss">
.header {
  height: 60px;
  width: auto;
}
.bottle-fixed {
  position: fixed;
  right: 0;
  height: 100%;
  top: 52px;
  background-color: white;
}
.modal-card {
  max-height: 85vh !important;
}
</style>
