<template>
  <div class="three-dee-component">
    <ThreeDee
      :uri="model.uri"
      :materialName="model.materialName"
      :textures="textures"
      :textureWidth="model.textureWidth"
      :textureHeight="model.textureHeight"
      :options="options"
      :debug="false"
    >
      <ThreeDeeOverlay :overlayMessage="'Kierrä kuvaa raahaamalla tai lähennä skrollaamalla'" />
    </ThreeDee>
  </div>
</template>

<script>
import { ThreeDee, ThreeDeeOverlay } from '@packagemedia/vue2-three-dee'
import { mapGetters } from 'vuex'

export default {
  name: 'ThreeDeePreview',

  components: {
    ThreeDee,
    ThreeDeeOverlay,
  },

  computed: {
    model() {
      return {
        uri: `${process.env.BASE_URL}3d/bottle-330ml-abott.glb`,
        materialName: 'Bottle_Label',
        textureWidth: 1280,
        textureHeight: 762,
      }
    },

    options() {
      return {
        cameraDistance: {
          min: 0.18,
          max: 1,
        },
        environment: {
          id: 'venice-sunset',
          name: 'Venice Sunset',
          path: `${process.env.BASE_URL}3d/venice_sunset_1k.hdr`,
          format: '.hdr',
        },
        zoom: 1.5,
      }
    },

    textures() {
      return [
        {
          uri: this.getResultImage,
          width: this.model.textureWidth,
          height: this.model.textureHeight,
          rotation: 0,
        },
      ]
    },

    ...mapGetters({
      getResultImage: 'getResultImage',
    }),
  },
}
</script>

<style lang="scss">
.three-dee-component {
  width: 100%;
  height: 70vh;
  min-height: 500px;
}
</style>
