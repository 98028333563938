let SVG_LABEL_CONFIGS = {
  // Keys should be named as same as we've files inside src/components/labels
  BeerLabel1: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 12
    },
    tagline1: {
      maxLength: 20
    },
    images: ['logo'],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['UnifrakturCook:wght@700&family=Texturina:wght@700&display=swap'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Texturina:wght@700&family=UnifrakturCook:wght@700&display=swap');"
  },
  BeerLabel2: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 12
    },
    tagline1: {
      maxLength: 20
    },
    images: ['logo'],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['UnifrakturCook:wght@700&family=Texturina:wght@700&display=swap'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Texturina:wght@700&family=UnifrakturCook:wght@700&display=swap');"
  },
  BeerLabel3: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 12
    },
    tagline1: {
      maxLength: 20
    },
    tagline2: {
      maxLength: 20
    },
    images: ['logo'],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Texturina:wght@700&display=swap'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Texturina:wght@700&display=swap');"
  },
  BeerLabel4: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 12
    },
    tagline1: {
      maxLength: 12
    },
    tagline2: {
      maxLength: 15
    },
    images: [],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Freckle+Face'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Freckle+Face&display=swap');"
  },
  BeerLabel5: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 20
    },
    tagline1: {
      maxLength: 20
    },
    tagline2: {
      maxLength: 20
    },
    tagline3: {
      maxLength: 2
    },
    images: [],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Bebas+Neue'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');"
  },
  BeerLabel6: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 20
    },
    tagline1: {
      maxLength: 20
    },
    tagline2: {
      maxLength: 20
    },
    tagline3: {
      maxLength: 2
    },
    images: [],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Bebas+Neue'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');"
  },
  BeerLabel7: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 20
    },
    tagline1: {
      maxLength: 20
    },
    tagline2: {
      maxLength: 20
    },
    tagline3: {
      maxLength: 2
    },
    images: [],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Bebas+Neue'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');"
  },
  BeerLabel8: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 14
    },
    tagline1: {
      maxLength: 14
    },
    tagline2: {
      maxLength: 14
    },
    images: [],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Homemade+Apple'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');"
  },
  BeerLabel9: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 30
    },
    tagline1: {
      maxLength: 30
    },
    images: ['mainPicture'],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Mukta:wght@300;500&display=swap'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;500&display=swap');",
    mainPictureViewport: {width: 185, height: 210}
  },
  BeerLabel10: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 30
    },
    tagline1: {
      maxLength: 30
    },
    images: ['mainPicture'],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Mukta:wght@300;500&display=swap'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;500&display=swap');",
    mainPictureViewport: {width: 110, height: 220}
  },
  BeerLabel11: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 30
    },
    tagline1: {
      maxLength: 30
    },
    images: ['mainPicture'],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Mukta:wght@300;500&display=swap'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;500&display=swap');",
    mainPictureViewport: {width: 110, height: 220}
  },
  BeerLabel12: {
    orderNumber: {
      maxLength: 7
    },
    title: {
      maxLength: 30
    },
    tagline1: {
      maxLength: 30
    },
    images: ['mainPicture'],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: ['Mukta:wght@300;500&display=swap'],
    fontsImportString: "@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;500&display=swap');",
    mainPictureViewport: {width: 185, height: 210}
  },
  BeerLabel13: {
    orderNumber: {
      maxLength: 7
    },
    images: ['mainPicture'],
    backText: {
      maxLength: 200,
      maxLines: 5,
      maxLengthPerLine: 40
    },
    fonts: null,
    fontsImportString: '',
    mainPictureViewport: {width: 165, height: 220}
  },
}

let DEFAULT_BACK_TEXT = 'Elämän hienot hetket koostuvat onnistumisista, naurusta, riemusta, yhdessä olosta sekä ' +
    'uuden luomisesta. Ne hetket kruunaa tunne, kun saa juuri siihen hetkeen suunnitellun oluen käteensä.'

export { SVG_LABEL_CONFIGS, DEFAULT_BACK_TEXT }
