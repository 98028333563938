import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'

import { DEFAULT_BACK_TEXT, SVG_LABEL_CONFIGS } from './config'
import doAsync from './async-util'
import * as types from './mutation-types'

import { setAxiosAuthToken } from '@/utils'
import { toPng } from '@/components/labels/utils'
import { setTag } from '@sentry/browser'

Vue.use(Vuex)

const local_store = localforage.createInstance({
  name: 'olvi-store',
})

const vuexPersist = new VuexPersistence({
  storage: local_store,
  reducer: (state) => {
    return JSON.parse(JSON.stringify(state))
  },
  asyncStorage: true,
})

const defaultTaglines = {
  title: 'Title',
  tagline1: 'Subtitle 1',
  tagline2: 'Subtitle 2',
  tagline4: 'Tagline 2',
}

const defaultLabeldata = {
  uuid: null,
  amount: 1,
  label: null, // Label svg result
  label_data: {
    ...defaultTaglines,
    title: 'Title',
    tagline3: '50',
    logo: '',
    mainPicture: '',
    backText: DEFAULT_BACK_TEXT,
    mainPictureSource: '',
    logoSource: '',
    mainPictureCroppieData: { points: [0, 0, 0, 0], zoom: 1, orientation: 1 },
    logoCroppieData: { points: [0, 0, 0, 0], zoom: 1, orientation: 1 },
    mainPictureDimensions: { width: 0, height: 0 },
    logoDimensions: { width: 0, height: 0 },
    selectedLabel: Object.keys(SVG_LABEL_CONFIGS)[8],
  },
}

const state = {
  ...defaultTaglines,
  customer: {
    company_name: null,
    customer_id: null,
    order_number: null,
  },
  token: null,
  order: {
    label: null,
  },
  authenticated: null,
  selectedLabel: Object.keys(SVG_LABEL_CONFIGS)[8], // For croppie
  labelsData: [
    {
      ...defaultLabeldata,
    },
  ],
  postContactData: null,
  postCustomerData: null,
  billAddress: false,
  orderAmount: 1,
  voucher_discount: null,
  selectedCartItem: 0,
  sourcePicture: null,
  pictureResolution: null
}

const updateTexture = (state) =>
  setTimeout(
    () =>
      toPng(document.getElementById('svg-component'), 1280, 762)
        .then((png) => {
          state.labelsData[state.selectedCartItem]['label_data'] = {
            ...state.labelsData[state.selectedCartItem]['label_data'],
            resultImage: png,
          }
        })
        .catch(console.warn),
    100
  )

const mutations = {
  addNewItem(state) {
    state.labelsData.push({ ...defaultLabeldata })
    state.selectedCartItem = state.labelsData.length - 1
  },
  setCartItem(state, idx) {
    state.selectedCartItem = idx
  },
  setCartItemAmount(state, { idx, amount }) {
    let labelsData = state.labelsData
    labelsData[idx] = {
      ...state.labelsData[idx],
      dirty: true,
      amount: amount,
    }
    state.labelsData = [...labelsData]
  },
  deleteCartItem(state, idx) {
    if (idx <= state.selectedCartItem)
      state.selectedCartItem = state.selectedCartItem > 0 ? state.selectedCartItem - 1 : 0
    if (state.labelsData.length === 1) {
      state.labelsData = [{ ...defaultLabeldata }]
      return
    }
    state.labelsData.splice(idx, 1)
  },
  resetData(state) {
    state.order = {
      label: null,
    }
    state.token = null
    state.customer = {
      company_name: null,
      customer_id: null,
      order_number: null,
    }
    state.selectedLabel = Object.keys(SVG_LABEL_CONFIGS)[8]
    state.labelsData = [{ ...defaultLabeldata }]
    state.postOrderData = null
    state.updateOrderData = null
    state.postContactData = null
    state.postCustomerData = null
    state.needBillingAddress = false
    state.orderAmount = 1
    state.voucher_discount = null
    state.selectedCartItem = 0
    state.sourcePicture = null
    state.pictureResolution = null
  },
  updateLabelData(state, data) {
    state.labelsData[state.selectedCartItem] = {
      ...state.labelsData[state.selectedCartItem],
      ...data,
    }
  },
  updateLabelDataById(state, { id, data }) {
    state.labelsData[id] = {
      ...state.labelsData[id],
      ...data,
    }
  },
  fakeTaglines(state) {
    let updatedValues = {}
    for (let key of Object.keys(defaultTaglines)) {
      if (state.labelsData[state.selectedCartItem]['label_data'][key] === defaultTaglines[key]) updatedValues[key] = ''
    }
    let labelsData = state.labelsData
    labelsData[state.selectedCartItem] = {
      ...labelsData[state.selectedCartItem],
      label_data: {
        ...labelsData[state.selectedCartItem]['label_data'],
        ...updatedValues,
      },
    }
    state.labelsData = [...labelsData]
  },
  revertFakedTaglines(state) {
    let updatedValues = {}
    for (let key of Object.keys(defaultTaglines)) {
      if (state.labelsData[state.selectedCartItem]['label_data'][key] === '') updatedValues[key] = defaultTaglines[key]
    }
    let labelsData = state.labelsData
    labelsData[state.selectedCartItem] = {
      ...labelsData[state.selectedCartItem],
      label_data: {
        ...labelsData[state.selectedCartItem]['label_data'],
        ...updatedValues,
      },
    }
    state.labelsData = [...labelsData]
  },
  setOrderAmount(store, value) {
    store.orderAmount = value
  },

  setLabelOptions(state, { key, value }) {
    state.labelsData[state.selectedCartItem]['label_data'] = {
      ...state.labelsData[state.selectedCartItem]['label_data'],
      [key]: value,
    }
    updateTexture(state)
  },

  changeLabel(store, direction) {
    let labels = Object.keys(SVG_LABEL_CONFIGS)
    let currentIndex = labels.indexOf(getters.getSelectedLabel(store))
    if (currentIndex + direction >= labels.length) direction = 0
    else if (currentIndex + direction === -1) direction = labels.length - 1
    else direction = currentIndex + direction
    store.labelsData[store.selectedCartItem]['label_data'] = {
      ...store.labelsData[store.selectedCartItem]['label_data'],
      selectedLabel: labels[direction],
    }
    store.selectedLabel = labels[direction]
    updateTexture(store)
  },
  changeBillAddressOption(store, newVal) {
    store.billAddress = newVal
  },

  setVoucherDiscount(state, data) {
    state.voucher_discount = data
  },

  setPictureResolution(state, res) {
    state.pictureResolution = res
  },

  setOrderData(store, data) {
    store.postOrderData = data['order']
    store.order = data['order']
  },

  setCustomerAndOrderData(store, data) {
    store.postOrderData = data['order']
    store.postCustomerData = data
    store.orderAmount = data['order']['amount']
    if (data['order']['labels'] != null) {
      store.labelsData = data['order']['labels']
      store.selectedLabel = data['order']['labels'][0]['label_data']['label_number']
    }
    store.order = data['order']
    store.customer = data['customer']
    store.postContactData = data['contact']
    store.token = data['token']['token']
    store.authenticated = true
    setAxiosAuthToken(data['token']['token'])
  },

  // mutation for POST CUSTOMER
  [types.POST_CUSTOMER.SUCCESS](state, info) {
    Vue.set(state, types.POST_CUSTOMER.loadingKey, false)
    Vue.set(state, types.POST_CUSTOMER.stateKey, info)
    setAxiosAuthToken(info['token']['token'])
  },

  [types.POST_CUSTOMER.PENDING](state) {
    Vue.set(state, types.POST_CUSTOMER.loadingKey, true)
  },

  [types.POST_CUSTOMER.FAILURE](state, errors) {
    Vue.set(state, types.POST_CUSTOMER.loadingKey, false)
    Vue.set(state, types.POST_CUSTOMER.errorKey, errors)
  },

  // mutation for POST ORDER
  [types.POST_ORDER.SUCCESS](state, info) {
    Vue.set(state, types.POST_ORDER.loadingKey, false)
    Vue.set(state, types.POST_ORDER.stateKey, info)
  },

  [types.POST_ORDER.PENDING](state) {
    Vue.set(state, types.POST_ORDER.loadingKey, true)
  },

  [types.POST_ORDER.FAILURE](state, errors) {
    Vue.set(state, types.POST_ORDER.loadingKey, false)
    Vue.set(state, types.POST_ORDER.errorKey, errors)
  },

  // mutation for UPDATE ORDER
  [types.UPDATE_ORDER.SUCCESS](state, info) {
    Vue.set(state, types.UPDATE_ORDER.loadingKey, false)
    Vue.set(state, types.UPDATE_ORDER.stateKey, info)
    Vue.set(state, types.POST_ORDER.stateKey, info)
  },

  [types.UPDATE_ORDER.PENDING](state) {
    Vue.set(state, types.UPDATE_ORDER.loadingKey, true)
  },

  [types.UPDATE_ORDER.FAILURE](state, errors) {
    Vue.set(state, types.UPDATE_ORDER.loadingKey, false)
    Vue.set(state, types.UPDATE_ORDER.errorKey, errors)
  },

  // mutation for GET SHIPPING PRICE
  [types.GET_SHIPPING_PRICE.SUCCESS](state, info) {
    Vue.set(state, types.GET_SHIPPING_PRICE.loadingKey, false)
    Vue.set(state, types.GET_SHIPPING_PRICE.stateKey, info)
  },

  [types.GET_SHIPPING_PRICE.PENDING](state) {
    Vue.set(state, types.GET_SHIPPING_PRICE.loadingKey, true)
  },

  [types.GET_SHIPPING_PRICE.FAILURE](state, errors) {
    Vue.set(state, types.GET_SHIPPING_PRICE.loadingKey, false)
    Vue.set(state, types.GET_SHIPPING_PRICE.errorKey, errors)
  },

  // mutation for GET PRICING
  [types.GET_PRICING.SUCCESS](state, info) {
    Vue.set(state, types.GET_PRICING.loadingKey, false)
    Vue.set(state, types.GET_PRICING.stateKey, info)
  },

  [types.GET_PRICING.PENDING](state) {
    Vue.set(state, types.GET_PRICING.loadingKey, true)
  },

  [types.GET_PRICING.FAILURE](state, errors) {
    Vue.set(state, types.GET_PRICING.loadingKey, false)
    Vue.set(state, types.GET_PRICING.errorKey, errors)
  },

  // mutation for POST contact
  [types.POST_CONTACT.SUCCESS](state, info) {
    Vue.set(state, types.POST_CONTACT.loadingKey, false)
    Vue.set(state, types.POST_CONTACT.stateKey, info)
  },

  [types.POST_CONTACT.PENDING](state) {
    Vue.set(state, types.POST_CONTACT.loadingKey, true)
  },

  [types.POST_CONTACT.FAILURE](state, errors) {
    Vue.set(state, types.POST_CONTACT.loadingKey, false)
    Vue.set(state, types.POST_CONTACT.errorKey, errors)
  },

  // mutation for UPDATE contact
  [types.UPDATE_CONTACT.SUCCESS](state, info) {
    Vue.set(state, types.UPDATE_CONTACT.loadingKey, false)
    Vue.set(state, types.UPDATE_CONTACT.stateKey, info)
  },

  [types.UPDATE_CONTACT.PENDING](state) {
    Vue.set(state, types.UPDATE_CONTACT.loadingKey, true)
  },

  [types.UPDATE_CONTACT.FAILURE](state, errors) {
    Vue.set(state, types.UPDATE_CONTACT.loadingKey, false)
    Vue.set(state, types.UPDATE_CONTACT.errorKey, errors)
  },

  // mutation for VOUCHER DISCOUNT
  [types.USE_VOUCHER_DISCOUNT.SUCCESS](state, info) {
    Vue.set(state, types.USE_VOUCHER_DISCOUNT.loadingKey, false)
    Vue.set(state, types.USE_VOUCHER_DISCOUNT.stateKey, info)
  },

  [types.USE_VOUCHER_DISCOUNT.PENDING](state) {
    Vue.set(state, types.USE_VOUCHER_DISCOUNT.loadingKey, true)
  },

  [types.USE_VOUCHER_DISCOUNT.FAILURE](state, errors) {
    Vue.set(state, types.USE_VOUCHER_DISCOUNT.loadingKey, false)
    Vue.set(state, types.USE_VOUCHER_DISCOUNT.errorKey, errors)
  },

  // mutation for POST ORDER
  [types.POST_CONTACT_EMAIL.SUCCESS](state, info) {
    Vue.set(state, types.POST_CONTACT_EMAIL.loadingKey, false)
    Vue.set(state, types.POST_CONTACT_EMAIL.stateKey, info)
  },

  [types.POST_CONTACT_EMAIL.PENDING](state) {
    Vue.set(state, types.POST_CONTACT_EMAIL.loadingKey, true)
  },

  [types.POST_CONTACT_EMAIL.FAILURE](state, errors) {
    Vue.set(state, types.POST_CONTACT_EMAIL.loadingKey, false)
    Vue.set(state, types.POST_CONTACT_EMAIL.errorKey, errors)
  },

  // mutation for GET ORDER
  [types.GET_ORDER.SUCCESS](state, info) {
    Vue.set(state, types.GET_ORDER.loadingKey, false)
    Vue.set(state, types.GET_ORDER.stateKey, info)
  },

  [types.GET_ORDER.PENDING](state) {
    Vue.set(state, types.GET_ORDER.loadingKey, true)
  },

  [types.GET_ORDER.FAILURE](state, errors) {
    Vue.set(state, types.GET_ORDER.loadingKey, false)
    Vue.set(state, types.GET_ORDER.errorKey, errors)
  },

  [types.POST_LABEL.SUCCESS](state, data) {
    Vue.set(state, types.POST_LABEL.loadingKey, false)
    state.labelsData[state.selectedCartItem] = {
      ...state.labelsData[state.selectedCartItem],
      uuid: data.uuid,
      label: data.label,
      amount: data.amount,
    }
  },

  [types.POST_LABEL.PENDING](state) {
    Vue.set(state, types.POST_LABEL.loadingKey, true)
  },

  [types.POST_LABEL.FAILURE](state, errors) {
    Vue.set(state, types.POST_LABEL.loadingKey, false)
    Vue.set(state, types.POST_LABEL.errorKey, errors)
  },

  [types.PATCH_LABEL.SUCCESS](state, data) {
    Vue.set(state, types.PATCH_LABEL.loadingKey, false)
    state.labelsData[state.selectedCartItem] = {
      ...state.labelsData[state.selectedCartItem],
      label: data.label,
    }
  },

  [types.PATCH_LABEL.PENDING](state) {
    Vue.set(state, types.PATCH_LABEL.loadingKey, true)
  },

  [types.PATCH_LABEL.FAILURE](state, errors) {
    Vue.set(state, types.PATCH_LABEL.loadingKey, false)
    Vue.set(state, types.PATCH_LABEL.errorKey, errors)
  },

  [types.DELETE_LABEL.SUCCESS](state, _) {
    Vue.set(state, types.PATCH_LABEL.loadingKey, false)
  },

  [types.DELETE_LABEL.PENDING](state) {
    Vue.set(state, types.PATCH_LABEL.loadingKey, true)
  },

  [types.DELETE_LABEL.FAILURE](state, errors) {
    Vue.set(state, types.PATCH_LABEL.loadingKey, false)
    Vue.set(state, types.PATCH_LABEL.errorKey, errors)
  },

  // mutation for POST_PICTURE
  [types.POST_PICTURE.SUCCESS](state, sourcePictureData) {
    Vue.set(state, types.POST_PICTURE.loadingKey, false)
    Vue.set(state, types.POST_PICTURE.stateKey, sourcePictureData)
    state.sourcePicture = sourcePictureData.image_url
  },

  [types.POST_PICTURE.PENDING](state) {
    Vue.set(state, types.POST_PICTURE.loadingKey, true)
  },

  [types.POST_PICTURE.FAILURE](state, errors) {
    Vue.set(state, types.POST_PICTURE.loadingKey, false)
    Vue.set(state, types.POST_PICTURE.errorKey, errors)
  },

  RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
}

const actions = {
  postCustomer(store, customerData) {
    return doAsync(store, {
      url: 'customer/',
      mutationTypes: types.POST_CUSTOMER,
      method: 'post',
      data: customerData,
    }).then(() => {
      store.commit('setOrderData', store.state.postCustomerData)
    })
  },
  postOrder(store, orderData) {
    return doAsync(store, {
      url: 'editor/',
      mutationTypes: types.POST_ORDER,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: orderData,
    })
  },
  addLabel(store, labelData) {
    return doAsync(store, {
      url: 'label/',
      mutationTypes: types.POST_LABEL,
      method: 'post',
      data: labelData,
    })
  },
  patchLabel(store, { data, uuid }) {
    return doAsync(store, {
      url: `label/${uuid}/`,
      mutationTypes: types.PATCH_LABEL,
      method: 'patch',
      data: data,
    })
  },
  deleteLabel(store, idx) {
    let uuid = store.state.labelsData[idx].uuid
    return doAsync(store, {
      url: `label/${uuid}/`,
      mutationTypes: types.DELETE_LABEL,
      method: 'delete',
    }).then(() => {
      store.commit('deleteCartItem', idx)
    })
  },
  updateOrder(store, orderData) {
    return doAsync(store, {
      url: 'editor/' + store.state.postOrderData.uuid + '/',
      mutationTypes: types.UPDATE_ORDER,
      method: 'patch',
      data: orderData,
    })
  },
  postContact(store, contactData) {
    return doAsync(store, {
      url: 'contact/',
      mutationTypes: types.POST_CONTACT,
      method: 'post',
      data: contactData,
    })
  },
  getPricing(store) {
    return doAsync(store, {
      url: 'pricing/',
      mutationTypes: types.GET_PRICING,
      method: 'get',
    })
  },
  getShippingPricing(store) {
    return doAsync(store, {
      url: 'shipping-price/',
      mutationTypes: types.GET_SHIPPING_PRICE,
      method: 'get',
    })
  },
  useVoucherDiscount(store, code) {
    return doAsync(store, {
      url: `voucher-discount/${code}`,
      mutationTypes: types.USE_VOUCHER_DISCOUNT,
      method: 'get',
    }).then(() => {
      store.commit('setVoucherDiscount', store.state.useVoucherDiscountData)
    })
  },
  postContactEmail(store, contactData) {
    return doAsync(store, {
      url: 'post-contact-info/',
      mutationTypes: types.POST_CONTACT_EMAIL,
      method: 'post',
      data: contactData,
    })
  },
  getOrderAndCustomer(store, orderData) {
    return doAsync(store, {
      url: 'editor/' + orderData.uuid + '/',
      mutationTypes: types.GET_ORDER,
      method: 'get',
      data: orderData,
    }).then(() => {
      store.commit('setCustomerAndOrderData', store.state.getOrderData)
    })
  },
  updateLabelsAmount(store) {
    for (let idx = 0; idx < store.state.labelsData.length; idx++) {
      if (store.state.labelsData[idx].dirty)
        store
          .dispatch('patchLabel', {
            data: { amount: store.state.labelsData[idx].amount },
            uuid: store.state.labelsData[idx].uuid,
          })
          .then(() => {
            store.commit('updateLabelDataById', { id: idx, data: { dirty: false } })
          })
    }
  },
  postPicture(store, pictureData) {
    return doAsync(store, {
        url: 'source-picture/',
        mutationTypes: types.POST_PICTURE,
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data'},
        data: pictureData
    })
  },
}

const getters = {
  getSelectedLabel(state) {
    return state.labelsData[state.selectedCartItem]['label_data']['selectedLabel']
  },
  getCustomer(state) {
    return state.customer
  },
  authToken: (state) => {
    if (state.postCustomerData != null) {
      return state.postCustomerData.token.token
    }
  },
  getShippingPrice(state) {
    if (state.getShippingPriceData.results && state.getShippingPriceData.results.length >= 1) {
      return state.getShippingPriceData.results[0]
    } else {
      return null
    }
  },
  getPricing(state) {
    if (state.getPricingData && state.getPricingData.results.length >= 1) {
      return state.getPricingData.results[0]
    } else {
      return null
    }
  },
  getDeliveryInfo(state) {
    let concat = (...args) => {
      let concatenated = ''
      for (let arg of args) concatenated += `${arg} `
      return concatenated
    }

    let billData = state.postContactData
    if (state.billAddress)
      return concat(billData.bill_name, billData.bill_street, billData.bill_town, billData.bill_zip)
    else return concat(billData.recv_name, billData.recv_street, billData.recv_town, billData.recv_zip)
  },
  getVoucherDiscount: (state) => {
    if (state.voucher_discount != null) {
      return state.voucher_discount
    }
    return null
  },
  getOrderUuid(state) {
    return state.order.uuid
  },
  getSelectedLabelUUID(state) {
    return state.labelsData[state.selectedCartItem].uuid
  },
  getTotalLabelsAmount(state) {
    return state.labelsData.reduce((x, y) => x + y.amount, 0)
  },
  getResultImage(state) {
    return state.labelsData[state.selectedCartItem]['label_data']['resultImage']
  },
  getPictureResolution(state) {
    return state.pictureResolution
  },
  getSourcePicture(state) {
    return state.sourcePicture
  }
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [vuexPersist.plugin],
})

export default store
